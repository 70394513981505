import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { MessageHandlerService } from '../message-handler/message-handler.service';

export class Message {
  public created: Date;

  private constructor(
    public val: string,
    public success: boolean,
    public dismissable: boolean,
    private operation?: string,
    private resp?: HttpErrorResponse
  ) {
    this.created = new Date();
  }

  static info(val: string, dismissable: boolean = true): Message {
    return new Message(val, true, dismissable);
  }

  static error(val: string, dismissable: boolean = true): Message {
    return new Message(val, false, dismissable);
  }

  static httpError(action: string, resp: HttpErrorResponse, dismissable: boolean = true): Message {
    return new Message(undefined, false, dismissable, action, resp);
  }

  process(messageHandler: MessageHandlerService): void {
    if (!this.val && this.operation && this.resp) {
      this.val = messageHandler.formatHttpError(this.operation, this.resp);
      setTimeout(() => messageHandler.recordError(this.val));
    } else if (this.success) {
      setTimeout(() => messageHandler.recordInfo(this.val));
    }
  }
}

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
})
export class MessageComponent {
  private _message: Message;

  @Input() set message(msg: Message | undefined) {
    this._message = msg;
    if (msg) {
      msg.process(this.messageHandler);
    }
  }

  get message(): Message {
    return this._message;
  }

  constructor(private messageHandler: MessageHandlerService) {}

  dismiss(): void {
    this._message = undefined;
  }
}
