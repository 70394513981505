<div class="msg-container">
  <div *ngIf="canViewFullMessageData" class="medis-toggle-group-wrapper mb-3 text-center">
    <mat-button-toggle-group [value]="viewType">
      <mat-button-toggle value="htmlPreview" (change)="viewType = $event.value">
        {{ 'HTML_PREVIEW' | translate }}
      </mat-button-toggle>
      <mat-button-toggle value="message" (change)="viewType = $event.value">
        {{ 'MESSAGE' | translate }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  @if(viewType === 'htmlPreview') {
  <div [innerHTML]="message.html"></div>
  } @else {
  <h4>EML: {{ message.eml }}</h4>
  <hr />
  <h4>{{ 'MESSAGE' | translate }}:</h4>
  <pre class="pb-3">{{ message.decodedMsg }}</pre>
  }
  <app-link-visualization
    *ngFor="let attachment of message.attachments"
    [link]="attachment"
    [allowRemove]="false"
    [canOpenFile]="canOpenFile"
  ></app-link-visualization>
</div>
