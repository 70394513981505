<div class="administration-table-container">
  <div class="add-new-container" id="topDiv" [ngClass]="preselectTypeName && 'ps-0 pt-1'">
    <button mat-button class="medis-button-basic" (click)="openRuleDialog()">
      {{ 'ADD_NEW_ITEM' | translate: { ITEM: 'RULE' | translate | lowercase } }}
    </button>
    <button mat-button (click)="openAuditTrailDialog($event)" class="medis-button-basic">
      {{ 'AUDIT_TRAIL_FOR_ALL' | translate: { ITEM: 'RULES' | translate | lowercase } }}
    </button>
  </div>

  <mat-table
    matSort
    class="medis-table"
    [dataSource]="dataSource"
    (matSortChange)="onSortChange($event)"
  >
    <ng-container matColumnDef="id">
      <mat-header-cell *matHeaderCellDef class="mat-cell-70-fixed">
        <div mat-sort-header>{{ 'ID' | translate }}</div>
        <mat-form-field (click)="$event.stopPropagation()" class="form-field-wrapper fs-10">
          <input
            matInput
            autocomplete="off"
            type="number"
            numbersOnly
            [(ngModel)]="searchedId"
            (keyup)="triggerSearch()"
          />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule" class="mat-cell-70-fixed">{{ rule.id }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header>{{ 'NAME' | translate }}</div>
        <mat-form-field (click)="$event.stopPropagation()" class="form-field-wrapper fs-10">
          <input
            matInput
            autocomplete="off"
            type="text"
            [(ngModel)]="searchedName"
            (keyup)="triggerSearch()"
          />
        </mat-form-field>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <div [matTooltip]="rule.name" class="truncated-text">{{ rule.name }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header>{{ 'TYPE' | translate }}</div>
        <app-search-select
          [disabled]="!!preselectTypeName"
          [style]="'fs-10'"
          [config]="typeConfig"
          [multiple]="true"
          [(selectedValue)]="searchedTypeIds"
          (selectedValueChange)="triggerSearch()"
        ></app-search-select>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        <div [matTooltip]="rule.typeName" class="truncated-text">{{ rule.typeName }}</div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="validFrom">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header>{{ 'VALID_FROM' | translate }}</div>
        <app-date-time-picker
          [timeZone]="'UTC'"
          [enableReset]="true"
          (valueChange)="onDateRangeChange($event)"
        ></app-date-time-picker>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{ rule.validFrom | date: 'dd MMM y, h:mm a':'UTC' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="validTo">
      <mat-header-cell *matHeaderCellDef>
        <div mat-sort-header>{{ 'VALID_TO' | translate }}</div>
        <app-date-time-picker
          [timeZone]="'UTC'"
          [enableReset]="true"
          (valueChange)="onDateRangeChange($event, false)"
        ></app-date-time-picker>
      </mat-header-cell>
      <mat-cell *matCellDef="let rule">
        {{ rule.validTo | date: 'dd MMM y, h:mm a':'UTC' }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="isActive">
      <mat-header-cell *matHeaderCellDef class="mat-cell-100-fixed">
        <div mat-sort-header>{{ 'ACTIVE_INACTIVE' | translate }}</div>
        <mat-button-toggle-group
          class="table-header-3-even-toggles icon-toggles"
          [(value)]="searchedStatus"
          (valueChange)="triggerSearch()"
        >
          <mat-button-toggle value="all" matTooltip="{{ 'ALL' | translate }}">
            <i class="fas fa-list medis-icon ms-0"></i>
          </mat-button-toggle>
          <mat-button-toggle value="1" matTooltip="{{ 'Active' | translate }}">
            <i class="fas fa-check-circle medis-icon-active ms-0"></i>
          </mat-button-toggle>
          <mat-button-toggle value="0" matTooltip="{{ 'INACTIVE' | translate }}">
            <i class="fas fa-times-circle medis-icon-inactive ms-0"></i>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let rule" class="mat-cell-100-fixed">
        <div
          class="administration-section-list-statuses activity truncated-text"
          matTooltip="{{ (rule.isActive ? 'DEACTIVATE' : 'ACTIVATE') | translate }}"
          [ngClass]="rule.isActive ? 'is-positive' : 'is-negative'"
          (click)="$event.stopPropagation(); switchActivity(rule)"
        >
          {{ (rule.isActive ? 'Active' : 'INACTIVE') | translate }}
        </div>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="audit">
      <mat-header-cell *matHeaderCellDef class="mat-cell-60-fixed centered-header header-text">
        {{ 'AUDIT_TRAIL' | translate }}
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let rule" class="mat-cell-60-fixed">
        <i
          class="fas fa-history medis-icon"
          (click)="openAuditTrailDialog($event, rule)"
          [matTooltip]="'AUDIT_TRAIL_FOR' | translate: { ITEM: rule.name }"
        ></i>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="emptyIndicator">
      <mat-header-cell *matHeaderCellDef class="medis-flex-grow centered">
        <app-loading-indicator [empty]="dataSource.data.length === 0"> </app-loading-indicator>
      </mat-header-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-header-row
      *matHeaderRowDef="['emptyIndicator']"
      [class.d-none]="dataSource.data.length !== 0"
    ></mat-header-row>
    <mat-row
      *matRowDef="let rule; columns: displayedColumns"
      (click)="openRuleDialog(rule)"
      class="cursor-pointer"
    ></mat-row>
  </mat-table>

  <app-paginated-navbar
    *ngIf="totalRecords"
    [pagesCount]="totalRecords"
    [pageSize]="pageSize"
    [pageSizeOptions]="pageSizeOptions"
    [pageIndex]="pageIndex"
    (pageChange)="onPageChange($event)"
  ></app-paginated-navbar>
</div>
