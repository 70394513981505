<ng-template #interactionContainer>
  <h2 mat-dialog-title>
    {{ 'INQUIRY' | translate }} {{ interaction | pk }}
    <app-reassign
      *ngIf="interaction && !hideActions"
      class="change-dropdown"
      [mode]="'users-teams'"
      [selectedItems]="[interaction]"
      [selectedUser]="interaction.assignedTo"
      (reassign)="onReassign()"
      [disabled]="!!reassignToDropdownTooltipText"
      [matTooltip]="reassignToDropdownTooltipText | translate"
      [matTooltipDisabled]="!reassignToDropdownTooltipText"
    ></app-reassign>
    <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
  </h2>
  <mat-dialog-content class="preview-content">
    <div *ngIf="interaction">
      <div *ngIf="interaction.mailFrom" class="text-14-500-dark">
        {{ 'From' | translate }}: {{ interaction.mailFrom }}
      </div>
      <div *ngIf="interaction.mailTo" class="text-14-500-dark">
        {{ 'To' | translate }}: {{ interaction.mailTo }}
      </div>
      <div *ngIf="interaction.incomingCcList" class="text-14-500-dark">
        {{ 'CC' | translate }}: {{ interaction.incomingCcList }}
      </div>
      <div *ngIf="interaction.mailSubject" class="text-14-500-dark medis-pb-20">
        {{ 'EMAIL_SUBJECT' | translate }}: {{ interaction.mailSubject }}
      </div>
      <div *ngIf="interaction.importedSource" class="text-14-500-dark medis-pb-20">
        {{ 'INQ_IMPORTED_FROM' | translate }} {{ interaction.importedSource }}
        {{ 'on' | translate }}
        {{ interaction.importedTs | dateChecker }}
      </div>
      <div
        *ngIf="!interaction.mailFrom && !interaction.importedSource"
        class="text-14-500-dark medis-pb-20"
      >
        {{ 'CREATED_MANUALLY' | translate }} {{ 'BY' | translate }}
        {{ interaction.insertedBy | displayInfo }} {{ 'on' | translate }}
        {{ interaction.createdTs | dateChecker }}
      </div>
      <span [innerHTML]="interaction.initialText | sanitizeHtml"></span>
    </div>
    <app-loading-indicator
      [loading]="!interaction"
      [empty]="!interaction?.initialText"
      [emptyLabel]="'NO_QUESTION_INSERTED'"
    ></app-loading-indicator>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="interaction?.questionAttachments?.length">
    <app-link-visualization
      *ngFor="let attachment of interaction.questionAttachments"
      [link]="attachment"
      [allowRemove]="false"
    ></app-link-visualization>
  </mat-dialog-actions>
</ng-template>

<ng-container *ngIf="isLoading || isPermissionDenied; else interactionContainer">
  <h2 mat-dialog-title>
    {{ 'INQUIRY' | translate }} {{ 'DETAILS' | translate | lowercase }}
    <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
  </h2>
  <mat-dialog-content>
    <app-loading-indicator
      [loading]="isLoading"
      [empty]="isPermissionDenied"
      [emptyLabel]="'PERMISSION_DENIED'"
    ></app-loading-indicator>
  </mat-dialog-content>
</ng-container>
