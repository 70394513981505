<div *ngFor="let event of historyData; let last = last">
  <div>
    <app-permission-edit [permissionName]="'View Enquiry Edited By'" class="d-inline-block">
      <span class="medis-clickable text-14-800-dark" (click)="previewUser(event.createdBy?.pk())">
        {{ event.createdBy | fullName }}
      </span>
    </app-permission-edit>
    {{ (event.changes && !last ? 'UPDATED_INQUIRER_DETAILS' : 'CREATED_INQUIRER') | translate }}
    {{ 'on' | translate }} {{ event.createdTs | dateChecker }}
  </div>
  <div *ngIf="event.changes" class="medis-frame-4-azure medis-pa-5">
    <div *ngFor="let change of event.changes">
      @if (change.preparedText) {
      <app-permission-edit
        [permissionName]="'View Enquirer'"
        [skip]="!piFields.includes(change.field)"
        [hasFallback]="true"
        class="d-inline-block"
      >
        <div [innerHTML]="change.preparedText"></div>
        <not-viewable
          ><b>{{ change.field | translate }}</b
          >:</not-viewable
        >
      </app-permission-edit>
      } @else if (!last) {
      <b>{{ change.field | translate }}</b
      >:
      <app-permission-edit
        [permissionName]="'View Enquirer'"
        [skip]="!piFields.includes(change.field)"
        class="d-inline-block"
      >
        {{ 'From' | translate }} <b>{{ change.from | translate }}</b> {{ 'To' | translate }}
        <b>{{ change.to | translate }}</b>
      </app-permission-edit>
      } @else {
      <b>{{ change.field | translate }}</b
      >:
      <app-permission-edit
        [permissionName]="'View Enquirer'"
        [skip]="!piFields.includes(change.field)"
        class="d-inline-block"
      >
        <b>{{ change.to | translate }}</b>
      </app-permission-edit>
      }
    </div>
  </div>
</div>
<app-loading-indicator [empty]="!historyData?.length"></app-loading-indicator>
