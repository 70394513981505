import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import {
  CountryService,
  EntitySearchParams,
  EntityService,
  NomenclaturesService,
  Scopes,
  TeamService,
} from 'app/modules/data-model/data-model.module';
import { BInteraction } from 'app/modules/data-model/interaction/interaction';
import { BEntity } from 'app/modules/data-model/entity/entity';
import { BTeam } from 'app/modules/data-model/user/user';
import { BRole } from '../data-model/role/role';
import { BCountry } from '../data-model/country/country';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-advanced-entity-search',
  templateUrl: './advanced-entity-search.component.html',
  styleUrls: ['./advanced-entity-search.component.scss'],
})
export class AdvancedEntitySearchComponent implements OnInit, OnDestroy {
  dialogRef: MatDialogRef<AdvancedEntitySearchComponent>;

  @Output() public selectedEntityChange: EventEmitter<BEntity> = new EventEmitter();
  @Output() public selectedEntitiesChange: EventEmitter<BEntity[]> = new EventEmitter();

  @Input() allowedTypes: string[];
  @Input() _interaction: BInteraction;
  @Input() selectedEntity: BEntity;
  @Input() allowedMulti: boolean = false;
  @Input() allowedRoles: BRole[];
  @Input() allowedTeams: BTeam[];
  @Input() preFilterRolesAndTeams: boolean;
  @Input() selectedEntities: BEntity[];

  private resultsSubscription = new Subscription();
  private _view: string = 'email';

  get view(): string {
    return this._view;
  }

  @Input()
  set view(value: string) {
    this._view = value;
  }

  searchType: string;
  searchLimit: number = 20;
  hideLoadMoreButton: boolean = false;

  // refactor
  textSearch: string;
  term = new UntypedFormControl();

  // data source
  displayedColumns = ['value', 'email', 'team'];
  dataSource: MatTableDataSource<BEntity>;

  searchTypes = { callcenter: false, user: false, department: false };
  searchFilters = { team: undefined, role: undefined, country: undefined };

  private ngUnsubscribe: Subject<any> = new Subject();
  teams: BTeam[];
  roles: BRole[];
  countries: BCountry[];

  constructor(
    private entityService: EntityService,
    private teamService: TeamService,
    private nomenclaturesService: NomenclaturesService,
    private countryService: CountryService
  ) {
    this.selectedEntities = new Array<BEntity>();
  }

  ngOnInit() {
    if (this.allowedTypes == undefined) {
      this.allowedTypes = [];
    }
    this.resetFilters();
    this.term.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((changes) => {
      if (changes == undefined) {
        return;
      }
      this.refreshSearch();
    });
    this.teamService.values.pipe(takeUntil(this.ngUnsubscribe)).subscribe((teams) => {
      this.teams = teams;
    });
    this.teamService.refreshIfEmpty();
    this.nomenclaturesService
      .get(new Scopes().roles())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.roles = res.roles;
      });
    this.countryService.values.pipe(takeUntil(this.ngUnsubscribe)).subscribe((countries) => {
      this.countries = countries;
    });
    this.countryService.refreshIfEmpty();
  }

  ngOnDestroy() {
    this.resultsSubscription.unsubscribe();
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  @Input()
  set interaction(val: BInteraction) {
    if (!val) {
      return;
    }
    this._interaction = val;
  }

  updateParamsFromFilters(params: EntitySearchParams): EntitySearchParams {
    if (this.allowedRoles && this.allowedRoles.length == 1) {
      this.searchFilters['role'] = this.allowedRoles[0].pk();
    }
    params.entityTypes = Object.keys(this.searchTypes)
      .filter((t) => this.searchTypes[t])
      .join(',');
    if (params.entityTypes.trim() === '') {
      params.entityTypes = this.allowedTypes?.join(',');
    }

    if (this.searchFilters['team']) {
      params.teamId = this.searchFilters['team'];
    }
    if (this.searchFilters['role']) {
      params.roleId = this.searchFilters['role'];
    }
    if (this.searchFilters['country']) {
      params.countryId = this.searchFilters['country'];
    }
    return params;
  }

  initialSearch() {
    // initial search is different because it uses the interaction as context (team, departments for interaction team..)
    let params = new EntitySearchParams();
    if (this.interaction) {
      params.interactionId = this.interaction.pk();
    }
    params = this.updateParamsFromFilters(params);
    params.limit = this.searchLimit;
    this.entityService.search(params);
    this.subscribeOnResults();
  }

  subscribeOnResults() {
    if (this.resultsSubscription) {
      this.resultsSubscription.unsubscribe();
    }
    this.entityService.values.subscribe((entities) => {
      this.dataSource = new MatTableDataSource<BEntity>(entities);
      this.hideLoadMoreButton = entities.length < this.searchLimit;
    });
  }

  refreshSearch() {
    if (!this.textSearch) {
      this.initialSearch();
      return;
    }
    let params = new EntitySearchParams();
    if (this.textSearch) {
      params.terms = this.textSearch;
    }
    params = this.updateParamsFromFilters(params);
    params.limit = this.searchLimit;
    this.entityService.search(params);
    this.subscribeOnResults();
  }

  loadMore() {
    this.searchLimit = this.searchLimit += 20;
    this.refreshSearch();
  }

  selectItem(entity: BEntity) {
    this.selectedEntity = entity;
    this.selectedEntityChange.emit(this.selectedEntity);
  }

  changeType(activeType: string) {
    this.searchType = activeType;
    this.refreshSearch();
  }

  getClassForRow(row: BEntity) {
    switch (row.class) {
      case 'department':
        return 'entity-department';
      case 'user':
        return 'entity-user';
      default:
        return undefined;
    }
  }

  loadDataRow(row: BEntity) {
    // if multi selection is allowed, save and continue to work
    if (!this.allowedMulti) {
      this.selectedEntityChange.emit(row);
    } else {
      if (!this.selectedEntities) {
        this.selectedEntities = new Array<BEntity>();
      }
      this.selectedEntities.push(row);
      this.term.setValue('');
    }
  }

  saveMulti() {
    this.selectedEntitiesChange.emit(this.selectedEntities);
    this.closeModalIfNeeded();
  }

  cancel() {
    this.selectedEntitiesChange.emit(undefined);
    this.selectedEntityChange.emit(undefined);
    this.closeModalIfNeeded();
  }

  closeModalIfNeeded() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  unselect(entity: BEntity) {
    this.selectedEntities = this.selectedEntities.filter(
      (e) => e.id != entity.id || e.class != entity.class
    );
  }

  isAlreadySelected(entity: BEntity): boolean {
    return (
      this.selectedEntities.filter((e) => e.id == entity.id && e.class == entity.class).length >= 1
    );
  }

  isOptionAvailable(option: string): boolean {
    if (this.allowedTypes.length == 0 || this.allowedTypes.indexOf(option) >= 0) {
      return true;
    }
    return false;
  }

  resetFilters() {
    this.searchTypes = { callcenter: false, user: false, department: false };
    this.searchFilters = { team: undefined, role: undefined, country: undefined };
    if (this.preFilterRolesAndTeams) {
      if (this.allowedTeams.length > 0) {
        this.searchFilters['team'] = this.allowedTeams[0].pk();
      } else {
        this.searchFilters['team'] = null;
      }

      if (this.allowedRoles.length > 0) {
        this.searchFilters['role'] = this.allowedRoles[0].pk();
      } else {
        this.searchFilters['role'] = null;
      }
    }
    Object.keys(this.searchTypes)
      .filter((t) => this.allowedTypes.length == 0 || this.allowedTypes.indexOf(t) >= 0)
      .map((key) => (this.searchTypes[key] = true));
    this.refreshSearch();
  }
}
