import { NameValue } from '@mi-tool/consts/const';
import {
  Config,
  newNamePkConfig,
  newNameStrIdConfig,
  newUserDisplayInfoPkConfig,
  newYesNoConfig,
} from 'app/common/common/search-select/search-select.config';
import { Nomenclatures } from 'app/modules/data-model/nomenclature/nomenclatures.service';

export enum FType {
  TEXT = 'text',
  NUMBER = 'number',
  NUMBER_RANGE = 'numberRange',
  DATE = 'date',
  SC = 'sc',
  MC = 'mc',
}

const FIELD_OPTIONS: { id: string; name: string; type: FType }[] = [
  { id: 'id', name: 'ID', type: FType.NUMBER },

  { id: 'idRange', name: 'ID range', type: FType.NUMBER_RANGE },

  { id: 'adr', name: 'ADR', type: FType.SC },
  { id: 'pqc', name: 'PQC', type: FType.SC },
  { id: 'srd', name: 'SRD needed', type: FType.SC },
  { id: 'offLabel', name: 'Off-label', type: FType.SC },
  { id: 'kee', name: 'KEE/KOL', type: FType.SC },
  { id: 'hospital', name: 'Hospital-based', type: FType.SC },
  { id: 'answerUnavailable', name: 'Answer unavailable', type: FType.SC },

  { id: 'assignedToIds', name: 'Assigned to', type: FType.MC },
  { id: 'teamIds', name: 'Team', type: FType.MC },
  { id: 'categoryIds', name: 'Category', type: FType.MC },
  { id: 'topicIds', name: 'Topic', type: FType.MC },

  { id: 'status', name: 'Status', type: FType.TEXT },

  { id: 'createdOn', name: 'Created on', type: FType.DATE },
];

export const FIELD_TO_TYPE = Object.assign(
  {},
  ...FIELD_OPTIONS.map((o) => {
    return { [o.id]: o.type };
  })
);

export const FIELD_CONFIG = newNameStrIdConfig(FIELD_OPTIONS, 'FIELD');

const CRITERIA_OPTIONS: { id: string; name: string; types: FType[] }[] = [
  {
    id: 'eq',
    name: 'Equal to',
    types: [FType.NUMBER],
  },
  {
    id: 'notEq',
    name: 'Not equal to',
    types: [FType.NUMBER],
  },
  {
    id: 'gt',
    name: 'Greater than',
    types: [FType.NUMBER],
  },
  {
    id: 'lt',
    name: 'Less than',
    types: [FType.NUMBER],
  },
  {
    id: 'gte',
    name: 'Greater or equal to',
    types: [FType.NUMBER],
  },
  {
    id: 'lte',
    name: 'Less or equal to',
    types: [FType.NUMBER],
  },
  {
    id: 'contains',
    name: 'Contains',
    types: [FType.TEXT],
  },
  {
    id: 'notContains',
    name: 'Not contains',
    types: [FType.TEXT],
  },
  {
    id: 'startsWith',
    name: 'Starts with',
    types: [FType.TEXT],
  },
  {
    id: 'endsWith',
    name: 'Ends with',
    types: [FType.TEXT],
  },
  {
    id: 'in',
    name: 'In',
    types: [FType.SC, FType.MC],
  },
  {
    id: 'notIn',
    name: 'Not in',
    types: [FType.SC, FType.MC],
  },
  {
    id: 'inRange',
    name: 'In range',
    types: [FType.NUMBER_RANGE, FType.DATE],
  },
  {
    id: 'notInRange',
    name: 'Not in range',
    types: [FType.NUMBER_RANGE, FType.DATE],
  },
];

const NUMBER_CRITERIA = [...CRITERIA_OPTIONS.filter((o) => o.types.includes(FType.NUMBER))];
const RANGE_CRITERIA = [...CRITERIA_OPTIONS.filter((o) => o.types.includes(FType.NUMBER_RANGE))];
const DROPDOWN_CRITERIA = [
  ...CRITERIA_OPTIONS.filter((o) => o.types.some((t) => [FType.SC, FType.MC].includes(t))),
];
const TEXT_CRITERIA = [...CRITERIA_OPTIONS.filter((o) => o.types.includes(FType.TEXT))];
const DATE_CRITERIA = [...CRITERIA_OPTIONS.filter((o) => o.types.includes(FType.DATE))];

function getNameStrIdConfig(options, label = 'CRITERIA'): Config {
  return newNameStrIdConfig(options, label);
}

export const FIELD_TO_CRITERIA_CONFIG = {
  id: getNameStrIdConfig(NUMBER_CRITERIA),
  idRange: getNameStrIdConfig(RANGE_CRITERIA),

  status: getNameStrIdConfig(TEXT_CRITERIA),

  createdOn: getNameStrIdConfig(DATE_CRITERIA),

  assignedToIds: getNameStrIdConfig(DROPDOWN_CRITERIA),
  teamIds: getNameStrIdConfig(DROPDOWN_CRITERIA),
  categoryIds: getNameStrIdConfig(DROPDOWN_CRITERIA),
  topicIds: getNameStrIdConfig(DROPDOWN_CRITERIA),

  srd: getNameStrIdConfig(DROPDOWN_CRITERIA),
  adr: getNameStrIdConfig(DROPDOWN_CRITERIA),
  pqc: getNameStrIdConfig(DROPDOWN_CRITERIA),
  offLabel: getNameStrIdConfig(DROPDOWN_CRITERIA),
  kee: getNameStrIdConfig(DROPDOWN_CRITERIA),
  hospital: getNameStrIdConfig(DROPDOWN_CRITERIA),
  answerUnavailable: getNameStrIdConfig(DROPDOWN_CRITERIA),
};

export function loadConfig(response: Nomenclatures = {} as Nomenclatures) {
  return {
    assignedToIds: newUserDisplayInfoPkConfig(response.users || [], 'USERS'),
    teamIds: newNamePkConfig(response.teams || [], 'TEAMS'),
    categoryIds: newNamePkConfig(response.categories || [], 'CATEGORIES'),
    topicIds: newNamePkConfig(response.topics || [], 'TOPICS'),

    srd: newYesNoConfig('SRD_NEEDED'),
    adr: newYesNoConfig('ADR_RELATED'),
    pqc: newYesNoConfig('COLUMN_PQC_RELATED'),
    offLabel: newYesNoConfig('OFF_LABEL'),
    kee: newYesNoConfig('KEE/KOL'),
    hospital: newYesNoConfig('HOSPITAL_BASED'),
    answerUnavailable: newYesNoConfig('ANSWER_UNAVAILABLE'),
  };
}

export const OPERATOR_OPTIONS: NameValue<string>[] = [
  { name: 'AND', value: 'AND', translationKey: 'OPTION_AND' },
  { name: 'OR', value: 'OR', translationKey: 'OPTION_OR' },
];
