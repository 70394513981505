<div *ngIf="document" class="smpc-lit-other">
  <app-document-available-actions
    *ngIf="adminMode"
    [document]="document"
  ></app-document-available-actions>
  <div *ngIf="document.currentVersion">
    <div class="aligned-right">
      <div *ngIf="document.currentVersion.published_date">
        {{ document.currentVersion.published_date | dateChecker }}
      </div>
      <div *ngIf="document.currentVersion.scheduled_expiration_date">
        {{ document.currentVersion.scheduled_expiration_date | dateChecker }}
      </div>
    </div>
    <!-- LITERATURE specific data -->
    <div *ngIf="document.type.code === 'lit'">
      <div *ngIf="document.internalTitle" class="text-16-400-dark">
        {{ 'ABSTRACT' | translate }}:
        <span class="text-16-800-dark">{{ document.internalTitle }}</span>
      </div>
      <div *ngIf="document.literature?.authors" class="text-16-400-dark">
        {{ 'AUTHORS' | translate }}:
        <span class="text-16-800-dark">{{ document.literature?.authors }}</span>
      </div>
      <div *ngIf="document.literature?.reference" class="text-16-400-dark">
        {{ 'REFERENCE' | translate }}:
        <span class="text-16-800-dark">{{ document.literature?.reference }}</span>
      </div>
    </div>
    <div *ngIf="document.productsDescription" class="text-16-400-dark">
      {{ 'PRODUCTS' | translate }}:
      <span class="text-16-800-dark">{{ document.productsDescription }}</span>
    </div>
    <div class="text-18-800-dark">{{ 'FILE' | translate }}:</div>
    <app-link-visualization
      *ngIf="!isPdf() || isDocumentWithExternalSourceUrl"
      [link]="document"
      [allowRemove]="false"
    ></app-link-visualization>
    <iframe *ngIf="isPdf()" [src]="link() | sanitizeUrl" class="pdf-preview-frame"></iframe>
  </div>
</div>
