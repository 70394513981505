import { Component, Input } from '@angular/core';
import { BMedicalDocument } from 'app/modules/data-model/medical-document/medical-document';
import { BMedicalDocumentVersionFile } from 'app/modules/data-model/medical-document-version-file/medical-document-version-file';
import {
  BMedicalDocumentVersion,
  RepositoryBrowserService,
} from 'app/modules/data-model/data-model.module';
import { MatDialog } from '@angular/material/dialog';
import { DocumentCreateComponent } from 'app/modules/repository/document-create/document-create.component';

@Component({
  selector: 'app-smpc-literature-other-link',
  templateUrl: './smpc-literature-other-link.component.html',
  styleUrls: ['./smpc-literature-other-link.component.scss'],
})
export class SmpcLiteratureOtherLinkComponent {
  @Input() document: BMedicalDocument;
  @Input() adminMode: boolean = false;
  @Input() isDocumentWithExternalSourceUrl: boolean = false;

  constructor(private matDialog: MatDialog, private repoBrowserService: RepositoryBrowserService) {}

  getMainDocumentFile(version?: BMedicalDocumentVersion): BMedicalDocumentVersionFile {
    if (!version) {
      version = this.document.currentVersion;
    }
    // return the object to be presented in the File propertly (for now the first one)
    // this.document.currentVersion && this.document.currentVersion.files just for security, we should retrieve always stuff with a current version and at least 1 file available...
    if (version && version.files) {
      return version.files[0];
    }
    return undefined;
  }

  getMainDocumentFileName(version?: BMedicalDocumentVersion): string {
    //just a convienient functino
    let docFile = this.getMainDocumentFile(version);
    if (docFile) {
      return docFile.name;
    }
    return '';
  }

  isPdf(): boolean {
    return this.getMainDocumentFileName().indexOf('.pdf') >= 0;
  }

  link(): string {
    return this.repoBrowserService.linkToMedicalDocumentFile(this.document.currentVersion.files[0]);
  }

  versionIsPdf(version: BMedicalDocumentVersion): boolean {
    return this.getMainDocumentFileName(version).indexOf('.pdf') >= 0;
  }

  finalizeLocalization() {
    // finalizeLocalization
    const ref = this.matDialog.open(DocumentCreateComponent);
    ref.componentInstance.finalizeLocalization(this.document);
  }
}
