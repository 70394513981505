import { BHistory } from 'app/modules/data-model/data-model.module';

export type EHistory = BHistory & {
  containerClass: string;
  shouldDisplayChannel: boolean;
};

export namespace EHistory {
  export function from(history: BHistory): EHistory {
    const eh = history as EHistory;
    eh.containerClass = resolveContainerClass(eh.category);
    eh.shouldDisplayChannel = shouldDisplayChannel(eh.category);
    return eh;
  }
}

function resolveContainerClass(category: string): string {
  if (['forward_to_dept', 'forward_to_user', 'fu_request', 'fu_response'].includes(category)) {
    return 'share';
  } else if (['error', 'bounce_detected'].includes(category)) {
    return 'error';
  }
  return '';
}

function shouldDisplayChannel(category: string): boolean {
  return ['answer', 'fu_request', 'fu_response', 'create', 'fu_attempt'].includes(category);
}
