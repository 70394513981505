import { DateRange } from 'app/common/types';
import { FIELD_TO_TYPE, FType } from 'app/common/common/constants/rules';
import { DateUtility } from 'app/common/date-utility';
import { cloneDeep } from 'lodash';

export type Operator = 'AND' | 'OR';

export type Cond = {
  id: number;
  field: string;
  criteria: string;
  values: string[] | number[] | DateRange[];
  operator: Operator;
};

export type Group = {
  id: number;
  operator: Operator;
  conds: Cond[];
};

export class BRule {
  id: number;
  name: string;
  typeId: number;
  validFrom: Date;
  validTo: Date;
  isActive: boolean;
  groups: Group[];

  typeName: string;

  isInvalid(): boolean {
    return (
      !this.name ||
      !this.typeId ||
      this.groups.some(
        (group) =>
          !group.operator ||
          group.conds.some(
            (c) =>
              !c.field ||
              !c.criteria ||
              !c.operator ||
              !c.values.length ||
              c.values.some((v) => !v) ||
              (FIELD_TO_TYPE[c.field] == FType.NUMBER_RANGE && c.values.length != 2) ||
              (FIELD_TO_TYPE[c.field] == FType.DATE &&
                !DateUtility.formatDateRange(c.values[0] as DateRange))
          )
      )
    );
  }

  static fromRest(json: any): BRule {
    if (json.validFrom && json.validTo) {
      json.validFrom = new Date(json.validFrom);
      json.validTo = new Date(json.validTo);
    }
    json.groups.forEach((g) =>
      g.conds.forEach((c) => {
        if (FIELD_TO_TYPE[c.field] == FType.DATE) {
          c.values[0].from = new Date(c.values[0].from);
          c.values[0].to = new Date(c.values[0].to);
        }
      })
    );
    return Object.assign(new BRule(), json);
  }

  static fromRestArray(json: any[]): BRule[] {
    return json && json.map((v) => BRule.fromRest(v));
  }
}

export function newCond(id: number = 1): Cond {
  return {
    id,
    field: 'id',
    criteria: 'eq',
    values: [],
    operator: 'AND',
  };
}

export function newGroup(id: number = 1): Group {
  return {
    id,
    operator: 'AND',
    conds: [newCond()],
  };
}

export function newRule(json: BRule | undefined): BRule {
  const source = (json && cloneDeep(json)) || { isActive: false, groups: [newGroup()] };
  return Object.assign(new BRule(), source);
}

export class BRuleType {
  id: number;
  name: string;

  static fromRest(json: any): BRuleType {
    return json && Object.assign(new BRuleType(), json);
  }

  static fromRestArray(json: any[]): BRuleType[] {
    return json && json.map((v) => BRuleType.fromRest(v));
  }
}
