export enum EAnswer {
  Yes = 'Yes',
  No = 'No',
}

export enum ESort {
  ASC = '',
  DESC = '-',
}

export enum EResetFilters {
  All = 'ALL',
  Mode = 'MODE',
}

export enum EInquirerNotificationModalMode {
  NOTIFICATION_WILL_BE_SENT,
  NOTIFICATION_SENT,
  SEND_NOTIFICATION,
}

export enum EInquirerNotificationModalAction {
  GET_TEXT = 'getNotificationText',
  GENERATE_TEXT = 'generateNotificationText',
}

export enum ECompanyType {
  MAIN_CLIENT = 1,
  CALL_CENTER = 2,
  LICENSE_PARTNER = 3,
}

export enum EMailImportDomainSettingsType {
  USER_DOMAINS = 1,
  SPAM_DOMAINS = 2,
}

export enum EAuditTrailDetailData {
  TEXT_DIFF = 'text_diff',
  DOC_DIFF_EXTERNAL = 'doc_diff_external',
  DETAILED_TEXT = 'detailed_text',
}

export enum EView {
  TEAM = 'team',
  DOCUMENT = 'document',
}

export enum HistoryStatuses {
  DISABLED = 'DISABLED',
}
