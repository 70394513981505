import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BEmail extends BaseModel {
  @rProperty() id: string;
  @rProperty() label: string;
  @rProperty() val: string;
  @rProperty() isMain: boolean;
  @rProperty() isDeleted: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    if (!json) {
      this.val = '';
    }
    for (let field of ['isMain', 'isDeleted']) {
      // Need to keep the true value since we can also clone the email which is already true
      this[field] = json[field] == 'TRUE' || json[field] === true ? true : false;
    }
  }

  static fromRest(json: any): BEmail {
    return json && Object.assign(new BEmail({}), json);
  }

  static fromRestArray(json: any[]): BEmail[] {
    return json && json.map((v) => BEmail.fromRest(v));
  }

  isValid(regexValidationOnly: boolean = false): boolean {
    const val = this.val && this.val.trim();
    if (!regexValidationOnly) {
      if (this.val && val.length === 0) {
        return false;
      }
      if (!this.isMain && !val) {
        return true;
      }
    }
    return this.regularEmailValidation(val) || this.devnullEmailValidation(val);
  }

  regularEmailValidation(email: string): boolean {
    return (
      /^[\p{L}\d._%+-]+@[\p{L}\d.-]+\.[\p{L}]{2,9}$/u.test(email) &&
      (email.match(/@/g) || []).length === 1
    );
  }

  devnullEmailValidation(email: string): boolean {
    return /^\S+@devnull$/.test(email) && (email.match(/@/g) || []).length === 1;
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment emailFragment on EmailNode {
    id
    label
    val
    isMain
    createdTs
    editedTs
    isDeleted
    importedId
    importedSource
    importedTs
  }
`;

export const fragmentNew = gql`
  fragment newEmailFragment on NewEmailNode {
    id
    label
    val
    isMain
    isDeleted
  }
`;

export const fragmentConnection = gql`
  fragment emailConnectionFragment on EmailNodeConnection {
    edges {
      node {
        ...emailFragment
      }
    }
  }
  ${fragment}
`;

export const fragmentNewConnection = gql`
  fragment newEmailConnectionFragment on NewEmailNodeConnection {
    edges {
      node {
        ...newEmailFragment
      }
    }
  }
  ${fragmentNew}
`;
