import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BNotificationOption extends BaseModel {
  @rProperty() id: string;
  @rProperty() order: number;
  @rProperty() name: string;
  @rProperty() description: string;
  @rProperty() textGuide: string;
  @rProperty() singleSelection: boolean;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {}

  static fromRest(json: any): BNotificationOption {
    return Object.assign(new BNotificationOption({}), json);
  }

  static fromRestArray(json: any[]): BNotificationOption[] {
    return json && json.map((v) => BNotificationOption.fromRest(v));
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment notificationOptionFragment on NotificationOptionNode {
    id
    order
    name
    description
    textGuide
    singleSelection
  }
`;
