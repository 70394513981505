<mat-checkbox
  class="mb-1"
  [(ngModel)]="masterChecked"
  [indeterminate]="masterIndeterminate"
  (change)="updateMasterSelection()"
>
  <span class="text-16-800-dark">
    {{ 'SELECT_ALL' | translate }}
  </span>
</mat-checkbox>
<div class="d-flex flex-row gap-4">
  @for (group of groups; track group.id) {
  <div>
    <mat-checkbox
      class="mb-1"
      [(ngModel)]="group.checked"
      [indeterminate]="group.indeterminate"
      (change)="updateGroupSelection(group)"
    >
      <span class="text-16-800-dark">{{ group.name | translate }}</span>
      @if (group.tooltip) {
      <i class="far fa-question-circle medis-icon" [matTooltip]="group.tooltip"></i>}
    </mat-checkbox>

    @for (item of group.items; track item.id) {
    <div>
      <mat-checkbox [(ngModel)]="item.checked" (change)="updateCheckbox(group)">
        {{ item.name | translate }}
        @if (item.tooltip) {
        <i class="far fa-question-circle medis-icon" [matTooltip]="item.tooltip"></i>}
      </mat-checkbox>
    </div>
    }
  </div>
  }
</div>
