import { Component, OnDestroy, OnInit } from '@angular/core';
import { BDMMessage } from 'app/modules/data-model/message/message';
import { AuthService } from 'app/auth/auth.service';
import { Subscription } from 'rxjs';
import {
  PERM_ADMINISTRATE_SYSTEM,
  PERM_VIEW_MAILBOXES,
} from 'app/common/common/constants/permissions';

@Component({
  selector: 'app-email-message',
  templateUrl: './email-message.component.html',
  styleUrls: ['./email-message.component.scss'],
})
export class EmailMessageComponent implements OnInit, OnDestroy {
  viewType: 'htmlPreview' | 'message' = 'htmlPreview';
  message: BDMMessage;
  canOpenFile: boolean = false;
  canViewFullMessageData: boolean = true;

  private subs = new Subscription();

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    if (this.canViewFullMessageData) {
      this.subs.add(
        this.authService.permissions.subscribe((handler) => {
          this.canOpenFile = handler.has(PERM_ADMINISTRATE_SYSTEM, PERM_VIEW_MAILBOXES);
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
