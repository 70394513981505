<h2 mat-dialog-title>
  <span>
    {{ permission?.name }}
    <i class="far fa-question-circle medis-icon" [matTooltip]="permission?.description"></i>
    <i
      class="fa fa-history medis-icon"
      (click)="openAuditTrailDialog()"
      [matTooltip]="'AUDIT_TRAIL_FOR_ALL' | translate: { ITEM: 'ROLES' | translate }"
    ></i>
  </span>
  <i class="far fa-times-circle medis-icon-large" mat-dialog-close></i>
</h2>

<mat-dialog-content>
  <div class="permission-picker-roles">
    @for (role of roles; track role.id) {
    <span class="text-15-700-dark">{{ role.name }}</span>
    <mat-checkbox
      [class.changed]="isChanged(role.pk())"
      [checked]="selectedRoleIds.includes(role.pk())"
      (change)="toggleRoleSelection(role.pk(), $event)"
    ></mat-checkbox>
    }
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <app-message [message]="message" class="w-100"></app-message>
  <button mat-button class="medis-button-main" [disabled]="changes.length === 0" (click)="save()">
    <i class="fas fa-save"></i>
    {{ 'SAVE' | translate }}
  </button>
  <button
    mat-button
    class="medis-button-main"
    [disabled]="changes.length === 0"
    (click)="save('close')"
  >
    {{ 'SAVE_AND_CLOSE' | translate }}
  </button>
  <button mat-button class="medis-button-reject" mat-dialog-close>
    {{ 'CANCEL' | translate }}
  </button>
</mat-dialog-actions>
