import { Validators } from '@angular/forms';

export type NameValue<T> = {
  name: string;
  value: T;
  translationKey?: string;
};

export const yesNoChoices: NameValue<boolean>[] = [
  { name: 'NO', value: false },
  { name: 'YES', value: true },
];

export const titleChoices: NameValue<string>[] = [
  { name: 'Dr.', value: 'Dr.', translationKey: 'TITLE_DR' },
  { name: 'PhD', value: 'PhD', translationKey: 'TITLE_PHD' },
  { name: 'Prof.', value: 'Prof.', translationKey: 'TITLE_PROF' },
  { name: 'Prof. Dr.', value: 'Prof. Dr.', translationKey: 'TITLE_PROF_DR' },
];

export namespace Genders {
  export const genderChoices: NameValue<string>[] = [
    { name: 'Mr.', value: 'male', translationKey: 'GENDER_MALE' },
    { name: 'Mrs.', value: 'female', translationKey: 'GENDER_FEMALE' },
  ];

  export function toText(value: string): string {
    const gender = genderChoices.find((g) => g.value === value);
    return gender ? gender.name : '';
  }
}

export const companies = [
  { name: 'Daiichi Sankyo' },
  { name: 'MSD' },
  { name: 'Servier' },
  { name: 'Astra Zeneca' },
  { name: 'Menarini' },
];

export const hasNumber = /\d/;
export const hasCapitalCase = /[A-Z]/;
export const hasSmallCase = /[a-z]/;
export const hasSpecialCharacters = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const onlyLetters = /^[a-zA-Z]+$/;
export const onlyLettersSpaceAndDash = /^[a-zA-Z]+(?:[- ][a-zA-Z]+)*$/;
export const onlyNumbers = /^[0-9]*$/;
export const simpleText = /^[\w\-\s\.\:]+$/;
export const textWithSpecialCharsRegEx = /^[\w\-\s\.\:(),\/+]+$/;
export const emailRegEx = /^\S+@\S+\.[a-zA-Z]{2,9}$/;

export const inquiryQuestionTextMinLength = 6;
export const inquiryAnswerTextMinLength = 6;

export const nameValidators = [Validators.required, Validators.pattern(onlyLettersSpaceAndDash)];

export const errorType = [
  { name: 'minlength', msg: 'MINIMUM_8_CHARACTERS' },
  { name: 'hasNumber', msg: 'AT_LEAST_1_DIGIT' },
  { name: 'hasCapitalCase', msg: 'AT_LEAST_1_UPPERCASE_CHARACTER' },
  { name: 'hasSmallCase', msg: 'AT_LEAST_1_LOWERCASE_CHARACTER' },
  { name: 'hasSpecialCharacters', msg: 'AT_LEAST_1_SPECIAL_CHARACTER' },
  { name: 'identifier', msg: 'SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES' },
];

export const manuals = [
  {
    icon: 'list',
    title: 'MedicalInformationRequests',
    url: '/doc/MISUserManual_MedicalInformationRequests.pdf',
  },
  {
    icon: 'list',
    title: 'UserProfileNavigation',
    url: '/doc/MISUserManual_UserProfileNavigation.pdf',
  },
  { icon: 'list', title: 'UserManualChanges', url: '/doc/User%20manual%20changes.docx' },
  { icon: 'list', title: 'iPad_How_To', url: '/doc/iPad_How_To.pdf' },
];

export const videoTutorials = [
  { title: 'MedIS_Assign_Inquiry', url: '/video/MedIS_Assign_Inquiry.mp4' },
  {
    title: 'MedIS_Documentation_Approval_Process',
    url: '/video/MedIS_Documentation_Approval_Process.mp4',
  },
  { title: 'MedIS_Documentation_Overview', url: '/video/MedIS_Documentation_Overview.mp4' },
  { title: 'MedIS_Inquiry_Documentation', url: '/video/MedIS_Inquiry_Documentation.mp4' },
  { title: 'MedIS_MIR_Inbox_Filters', url: '/video/MedIS_MIR_Inbox_Filters.mp4' },
  { title: 'MedIS_Merge_MIR', url: '/video/MedIS_Merge_MIR.mp4' },
  { title: 'MedIS_New_Documentation', url: '/video/MedIS_New_Documentation.mp4' },
  { title: 'MedIS_New_Inquiry', url: '/video/MedIS_New_Inquiry.mp4' },
  { title: 'MedIS_Out_Of_Office', url: '/video/MedIS_Out_Of_Office.mp4' },
  { title: 'MedIS_Prepare_Custom_Answer', url: '/video/MedIS_Prepare_Custom_Answer.mp4' },
  { title: 'MedIS_Split_MIR_Part_I', url: '/video/MedIS_Split_MIR_Part_I.mp4' },
  { title: 'MedIS_Split_MIR_Part_II', url: '/video/MedIS_Split_MIR_Part_II.mp4' },
];

export namespace Channels {
  const OUTGOING: Channel[] = [
    { key: 'email', translationKey: 'Email', isActive: true },
    { key: 'telephone', translationKey: 'PHONE', isActive: true },
    { key: 'fax', translationKey: 'FAX', isActive: true },
    { key: 'letter', translationKey: 'LETTER', isActive: true },
    { key: 'f2f_site_visit', translationKey: 'F2F_SITE_VISIT', isActive: true },
    { key: 'f2f_congress', translationKey: 'F2F_CONGRESS', isActive: true },
    { key: 'f2f_adboard', translationKey: 'F2F_ADBOARD', isActive: true },
    { key: 'f2f', translationKey: 'F2F_OTHER', isActive: true },
    { key: 'webinar_site_visit', translationKey: 'WEBINAR_SITE_VISIT', isActive: true },
    { key: 'webinar_congress', translationKey: 'WEBINAR_CONGRESS', isActive: true },
    { key: 'webinar_adboard', translationKey: 'WEBINAR_ADBOARD', isActive: true },
    { key: 'webinar', translationKey: 'WEBINAR_OTHER', isActive: true },
    { key: 'chat', translationKey: 'CHAT', isActive: false },
    { key: 'courier', translationKey: 'COURIER', isActive: false },
    { key: 'repdelivery', translationKey: 'REP_DELIVERY', isActive: false },
    { key: 'system', translationKey: 'SYSTEM', isActive: false },
  ];

  const INCOMMING = OUTGOING.concat([
    { key: 'web', translationKey: 'WEB_FORM', isActive: true },
    { key: 'app', translationKey: 'App', isActive: false },
    { key: 'crm', translationKey: 'CRM', isActive: false },
    { key: 'steeprock', translationKey: 'Steep Rock', isActive: false },
    { key: 'veeva', translationKey: 'Veeva', isActive: false },
  ]);

  /** Advanced Search Incomming channels */
  export const AS_INCOMMING: NameValue<string>[] = [
    { value: 'chat', name: 'Chat' },
    { value: 'email', name: 'Email' },
    { value: 'f2f', name: 'F2F' },
    { value: 'fax', name: 'Fax' },
    { value: 'letter', name: 'Letter' },
    { value: 'telephone', name: 'Phone' },
    { value: 'veeva', name: 'Veeva' },
    { value: 'web', name: 'Web Form' },
  ];

  /** Advanced Search Outgoing channels */
  export let AS_OUTGOING: NameValue<string>[] = [
    { value: 'chat', name: 'Chat' },
    { value: 'courier', name: 'Courier' },
    { value: 'email', name: 'Email' },
    { value: 'f2f', name: 'F2F' },
    { value: 'fax', name: 'Fax' },
    { value: 'letter', name: 'Letter' },
    { value: 'telephone', name: 'Phone' },
    { value: 'repdelivery', name: 'Rep. Delivery' },
  ];

  export function resolveIncomming(current?: string): Channel[] {
    return resolve(INCOMMING, current);
  }

  export function resolveOutgoing(current?: string): Channel[] {
    return resolve(OUTGOING, current);
  }

  export function resolveTranslationKey(channel: string): string {
    const ch = INCOMMING.find((c) => c.key === channel);
    return (ch && ch.translationKey) || channel;
  }

  function resolve(channels: Channel[], current?: string): Channel[] {
    let activeChannels = channels.filter((c) => c.isActive || c.key === current);
    if (current && activeChannels.findIndex((c) => c.key === current) === -1) {
      activeChannels = [{ key: current, translationKey: current, isActive: true }].concat(
        activeChannels
      );
    }
    return activeChannels;
  }
}

export type Channel = {
  key: string;
  translationKey: string;
  isActive: boolean;
};

export const userRoles = {
  sysAdmin: ['sys_admin'],
  userAdmin: ['user_admin'],
  admin: ['sys_admin', 'user_admin'],
  mim: ['mim'],
  documentManager: ['doc_manager'],
  eventManager: ['event_manager'],
  medicalProcessing: ['mim', 'ma', 'msl', 'msla'],
  callCenter: ['call'],
  documentProcessing: [
    'doc_comm_app',
    'doc_content_app',
    'doc_dsafety_app',
    'doc_editor',
    'doc_legal_app',
    // 'doc_manager', @TODO confirm if needed?
    'doc_reg_app',
  ],
};

export const LABEL_OPTIONS = {
  home: 'Home',
  hospital: 'Hospital',
  office: 'Office',
  fax: 'Fax',
};

export const LEFT_BAR_HISTORY_CATEGORIES = ['fu_response', 'bounce_detected', 'error'];

export const LEFT_BAR_HISTORY_CATEGORIES_MAP: Record<
  typeof LEFT_BAR_HISTORY_CATEGORIES[number],
  string
> = {
  fu_response: 'FOLLOW_UP_RECEIVED',
  bounce_detected: 'ERROR_EMAIL_COULD_NOT_BE_DELIVERED',
  error: 'ERROR',
} as const;

export const PERIOD_LABELS_MAP: Record<string, string> = {
  m: 'Minutes',
  h: 'Hours',
  d: 'Days',
  w: 'Weeks',
  M: 'Months',
} as const;

export const priorityLevel = { NORMAL: 1, HIGH: 2, CRITICAL: 3 };
