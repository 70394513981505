<div *ngIf="message" class="medis-flex-container-between">
  <i *ngIf="message.dismissable" class="far fa-times-circle medis-icon invisible"></i>
  @if (message.success) {
  <div class="text-13-800-green medis-flex-grow text-center">
    <i class="fas fa-check-circle medis-icon-active non-clickable"></i>
    {{ message.val | translate }}
  </div>
  } @else {
  <mat-error class="medis-flex-grow text-center">{{ message.val | translate }}</mat-error>
  }
  <i
    *ngIf="message.dismissable"
    class="far fa-times-circle medis-icon"
    (click)="dismiss()"
    [matTooltip]="'DISMISS' | translate"
  ></i>
</div>
