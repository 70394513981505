import { gql } from 'apollo-angular';

import { base64ToPK, BaseModel, parseArray, parseAttr, rProperty } from '../data-model';

import { BMailbox } from 'app/modules/data-model/mailbox/mailbox';
import * as MRole from '../role/role';
import { BRole } from '../role/role';
import { BLanguage } from '../language/language';
import { BProduct, BRegion, MProduct } from '../region/region';
import { BAddress } from '../address/address';
import { BPhone } from '../phone/phone';
import { BEmail } from 'app/modules/data-model/email/email';
import { BCategory } from '../category/category';
import { BTopic } from '../topic/topic';
import { BCountry } from '../country/country';
import * as MNotificationType from 'app/modules/data-model/notification-type/notification-type';
import { BNotificationType } from 'app/modules/data-model/notification-type/notification-type';
import * as MNotificationFrequency from 'app/modules/data-model/notification-frequency/notification-frequency';
import { BNotificationFrequency } from 'app/modules/data-model/notification-frequency/notification-frequency';
import * as MNotificationOption from 'app/modules/data-model/notification-option/notification-option';
import { BNotificationOption } from 'app/modules/data-model/notification-option/notification-option';
import { BTerritory } from '../territory/territory';
import { BGeography } from '../geography/geography';
import { BProductGroup } from '../product/product-group';
import { BCurrentObjectState } from '../workflow/current-object-state';
import * as MState from 'app/modules/data-model/workflow/state';
import * as MCurrentObjectState from 'app/modules/data-model/workflow/current-object-state';
import * as MLanguage from 'app/modules/data-model/language/language';
import * as MCountry from 'app/modules/data-model/country/country';
import * as MCategory from 'app/modules/data-model/category/category';
import * as MTopic from 'app/modules/data-model/topic/topic';
import { Genders, userRoles } from '@mi-tool/consts/const';
import { BAffiliation } from './affiliation.base';
import * as MAffiliation from './affiliation.namespace';
import { BCompany } from '../company/company';
import { BTherapeuticArea } from '../medical-document/medical-document';

export class BAuthRequest extends BaseModel {
  @rProperty() id: string;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) handledTs: Date;
  @rProperty() isDelegated: boolean;

  //relations
  status: BCurrentObjectState;
  user: BUser;
  requestRole: BRole;
  requestTeam: BTeam;
  handledBy: BUser;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.user = parseAttr<BUser>(json, BUser, 'user');
    this.requestRole = parseAttr<BRole>(json, BRole, 'requestRole');
    this.requestTeam = parseAttr<BTeam>(json, BTeam, 'requestTeam');
    this.status = parseAttr<BCurrentObjectState>(json, BCurrentObjectState, 'status');
    this.handledBy = parseAttr<BUser>(json, BUser, 'handledBy');
  }
}

export namespace MUser {
  export const fragmentShort = gql`
    fragment userFragmentShort on UserNode {
      id
      firstName
      lastName
    }
  `;

  // fragments will contain only actual values of the object, no references to other tables
  export const fragment = gql`
    fragment userFragment on UserNode {
      id
      title
      gender
      username
      firstName
      lastName
      email
      isActive
      gender
      jobDescription
      createdTs
      institution
      department
      outOfOffice
      outOfOfficeFrom
      outOfOfficeTo
      outOfOfficeImmediate
      importedId
      importedSource
      importedTs
      firstImportTs
    }
  `;

  export const fragmentConnection = gql`
    fragment userConnectionFragment on UserNodeConnection {
      edges {
        node {
          ...userFragment
        }
      }
    }
    ${fragment}
  `;
}

export class BTeam extends BaseModel {
  @rProperty() id: string;
  @rProperty() code: string;
  @rProperty() name: string;
  @rProperty() timezoneCode: string;
  @rProperty() notifyInquirer: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() salesFullAnswerOnLabel: boolean;
  @rProperty() segregatedData: boolean;
  @rProperty() restrictedTeam: boolean;
  @rProperty() privateTeam: boolean;
  @rProperty() autoAssignmentOn: string;
  @rProperty() documentApprovalWithinEnqmed: boolean;
  @rProperty() notifySales: boolean;
  enquiryAnswerApproval: boolean;

  isActive = true;

  //relations
  defaultLanguage: BLanguage;
  parentTeam: BTeam;
  affiliations: BAffiliation[];
  productGroups: BProductGroup[];
  countries: BCountry[];
  overrideClusterRoot: BTeam;
  mailboxIn: BMailbox;
  mailboxOut: BMailbox;

  company: BCompany;
  region: BRegion;
  therapeuticAreas: BTherapeuticArea[];
  products: BProduct[];

  countryProductsDefinitionData: Array<{
    id: string;
    country: BCountry;
    products: BProduct[];
  }>;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.parentTeam = parseAttr<BTeam>(json, BTeam, 'parentTeam');
    this.affiliations = parseArray<BAffiliation>(json, BAffiliation, 'activeAffiliations');
    this.productGroups = parseArray<BProductGroup>(json, BProductGroup, 'productGroups');
    this.countries = parseArray<BCountry>(json, BCountry, 'countries');
    this.overrideClusterRoot = parseAttr<BTeam>(json, BTeam, 'overrideClusterRoot');
    this.mailboxIn = parseAttr<BMailbox>(json, BMailbox, 'incomingMailbox');
    this.mailboxOut = parseAttr<BMailbox>(json, BMailbox, 'outgoingMailbox');
  }

  static fromRest(json: any): BTeam {
    if (json) {
      json.timezoneCode = json.timezone_code;
      json.salesFullAnswerOnLabel = json.sales_full_answer_on_label;
      json.segregatedData = json.segregated_data;
      json.notifyInquirer = json.notify_inquirer;
      json.restrictedTeam = json.restricted_team;
      json.privateTeam = json.private_team;
      json.autoAssignmentOn = json.auto_assignment_on;
      json.productGroups = BProductGroup.fromRestArray(json.productGroups);
      json.countries = BCountry.fromRestArray(json.countries);
      json.affiliations = BAffiliation.fromRestArray(json.activeAffiliations);
      json.parentTeam = BTeam.fromRest(json.parentTeam);
      json.overrideClusterRoot = BTeam.fromRest(json.overrideClusterRoot);
      json.mailboxIn = BMailbox.fromRest(json.incomingMailbox);
      json.mailboxOut = BMailbox.fromRest(json.outgoingMailbox);
      json.company = BCompany.fromRest(json.company);
      json.region = BRegion.fromRest(json.region);
      json.therapeuticAreas = BTherapeuticArea.fromRestArray(json.therapeuticAreas);
      json.products = BProduct.fromRestArray(json.products);
      json.documentApprovalWithinEnqmed =
        !!json.document_approval_within_enqmed || !!json.documentApprovalWithinEnqmed;
      json.notifySales = !!json.notifySales;

      return Object.assign(new BTeam({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BTeam[] {
    return json && json.map((v) => BTeam.fromRest(v));
  }

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }

  getMId() {
    return base64ToPK(this.id);
  }

  getMName() {
    return this.name;
  }
}

export class BUserNotification extends BaseModel {
  @rProperty() id: string;
  @rProperty() notifType: BNotificationType;
  @rProperty() notifFrequency: BNotificationFrequency;
  @rProperty() user: BUser;

  notifOptions: BNotificationOption[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.notifOptions = parseArray<BNotificationOption>(json, BNotificationOption, 'notifOptions');
  }

  static fromRest(json: any): BUserNotification {
    json.notifType = BNotificationType.fromRest(json.notifType);
    return Object.assign(new BUserNotification({}), json);
  }

  static fromRestArray(json: any[]): BUserNotification[] {
    return json.map((v) => BUserNotification.fromRest(v));
  }
}

export class BAnswerTemplate extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  @rProperty() type: string;
  @rProperty() text: string;
  @rProperty() userId: string;
  @rProperty() language: BLanguage;
  @rProperty() offLabel: boolean;
  @rProperty() hcp: boolean;
  @rProperty() position: string;
  @rProperty() team: BTeam;
  @rProperty() isDefaultTemplate: boolean;
  @rProperty() answerOrder: number;
  @rProperty() isManuallyModified: boolean;

  products: BProduct[];
  countries: BCountry[];
  categories: BCategory[];
  topics: BTopic[];
  previewText: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  static fromRest(json: any): BAnswerTemplate {
    const template = new BAnswerTemplate({});
    json.language = BLanguage.fromRest(json.language);
    json.team = BTeam.fromRest(json.team);
    json.products = BProduct.fromRestArray(json.products);
    json.countries = BCountry.fromRestArray(json.countries);
    Object.assign(template, json);
    return template;
  }

  static fromRestArray(json: any[]): BAnswerTemplate[] {
    return json.map((v) => BAnswerTemplate.fromRest(v));
  }

  init(json: any) {
    this.products = parseArray<BProduct>(json, BProduct, 'products');
    this.countries = parseArray<BCountry>(json, BCountry, 'countries');
  }

  pk(): number {
    return base64ToPK(this.id);
  }

  resolveCountryNamesCSV(): string {
    return this.countries.map((c) => c.name).join(', ');
  }

  resolveProductNamesCSV(): string {
    return this.products.map((p) => p.name).join(', ');
  }
}

export class BAlignment extends BaseModel {
  @rProperty() id: string;
  @rProperty() name: string;
  //relations
  geographies: BGeography[];
  users: BUser[];

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.geographies = parseArray<BGeography>(json, BGeography, 'geographies');
    this.users = parseArray<BUser>(json, BUser, 'users');
  }

  static fromRest(json: any): BAlignment {
    return json && Object.assign(new BAlignment({}), json);
  }

  static fromRestArray(json: any[]): BAlignment[] {
    return json && json.map((v) => BAlignment.fromRest(v));
  }

  pk(): number {
    if (!this.id) {
      return null;
    }
    return base64ToPK(this.id);
  }
}

export class BUser extends BaseModel {
  @rProperty() id: string;
  @rProperty() title: string;
  @rProperty() gender: string;
  @rProperty() username: string;
  @rProperty() firstName: string;
  @rProperty() lastName: string;
  @rProperty() email: string; // this is the main email of the user

  @rProperty() isActive: boolean;
  @rProperty() jobDescription: string;
  @rProperty() institution: string;
  @rProperty() department: string;
  @rProperty() outOfOffice: boolean;
  @rProperty() outOfOfficeImmediate: boolean;
  @rProperty(Date) outOfOfficeFrom: Date;
  @rProperty(Date) outOfOfficeTo: Date;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) lastActiveTs: Date;
  lastEditedTs?: Date;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: Date;
  @rProperty(Date) firstImportTs: Date;
  hasApprovePermissions: boolean;
  hasProcessPermissions: boolean;

  // relations
  mainAddress: BAddress;
  mainPhone: BPhone;
  language: BLanguage;
  territory: BTerritory;
  affiliations: BAffiliation[];
  role: BRole[];
  deputy: BUser;
  emails: BEmail[];
  phones: BPhone[];
  addresses: BAddress[];
  autoAssignTo: BUser;
  autoAssignedBy: BUser[];
  approvalAssignTo: BUser[];
  approvalAssignBy: BUser[];
  authRequests: BAuthRequest[];
  activeNotifications: BUserNotification[];
  // default list access
  defaultListProducts: BProduct[];
  canViewProducts: BProduct[];
  documentTeams: BTeam[];
  alignments: BAlignment[];

  answerTemplates: BAnswerTemplate[];
  delegators: BUser[];

  constructor(json) {
    json = json || {};
    super(json);
    this.init(json);
  }

  init(json: any) {
    this.affiliations = parseArray<BAffiliation>(json, BAffiliation, 'affiliations');
    this.language = parseAttr<BLanguage>(json, BLanguage, 'language');
    this.territory = parseAttr<BTerritory>(json, BTerritory, 'territory');

    this.deputy = parseAttr<BUser>(json, BUser, 'deputy');
    this.mainAddress = parseAttr<BAddress>(json, BAddress, 'mainAddress');
    this.mainPhone = parseAttr<BPhone>(json, BPhone, 'mainPhone');

    this.emails = parseArray<BEmail>(json, BEmail, 'emails');
    this.phones = parseArray<BPhone>(json, BPhone, 'phones');
    this.addresses = parseArray<BAddress>(json, BAddress, 'addresses');
    this.autoAssignTo = parseAttr<BUser>(json, BUser, 'autoAssignTo');
    this.autoAssignedBy = parseArray<BUser>(json, BUser, 'autoAssignedBy');
    this.approvalAssignTo = parseArray<BUser>(json, BUser, 'approvalAssignTo');
    this.approvalAssignBy = parseArray<BUser>(json, BUser, 'approvalAssignBy');
    this.authRequests = parseArray<BAuthRequest>(json, BAuthRequest, 'authRequests');
    this.activeNotifications = parseArray<BUserNotification>(
      json,
      BUserNotification,
      'activeNotifications'
    );

    this.defaultListProducts = parseArray<BProduct>(json, BProduct, 'defaultListProducts');
    this.defaultListProducts = this.defaultListProducts.sort((x, y) => (x.name >= y.name ? 1 : -1));
    this.canViewProducts = parseArray<BProduct>(json, BProduct, 'canViewProducts');
    this.documentTeams = parseArray<BTeam>(json, BTeam, 'documentTeams');
    this.answerTemplates = parseArray<BAnswerTemplate>(json, BAnswerTemplate, 'answerTemplates');
    this.canViewProducts = this.canViewProducts.sort((x, y) => (x.name >= y.name ? 1 : -1));

    if (this.phones.length == 0) {
      this.phones = [new BPhone('')];
    }
    if (this.emails.length == 0) {
      this.emails = [new BEmail('')];
    }
    if (this.addresses.length == 0) {
      this.addresses = [new BAddress('')];
    }
    this.alignments = parseArray<BAlignment>(json, BAlignment, 'alignments');
  }

  static fromRest(json: any): BUser {
    if (json) {
      json.affiliations = BAffiliation.fromRestArray(json.affiliations);
      json.approvalAssignTo = BUser.fromRestArray(json.approvalAssignTo);
      json.approvalAssignBy = BUser.fromRestArray(json.approvalAssignBy);
      json.autoAssignedBy = BUser.fromRestArray(json.autoAssignedBy);
      json.autoAssignTo = BUser.fromRest(json.autoAssignTo);
      json.createdTs = json.createdTs && new Date(json.createdTs);
      json.lastActiveTs = json.lastActiveTs && new Date(json.lastActiveTs);
      json.lastEditedTs = json.lastEditedTs && new Date(json.lastEditedTs);
      json.deputy = json.deputy && BUser.fromRest(json.deputy);
      json.delegators = json.delegators && BUser.fromRestArray(json.delegators);
      json.canViewProducts = json.canViewProducts && BProduct.fromRestArray(json.canViewProducts);
      json.defaultListProducts =
        json.defaultListProducts && BProduct.fromRestArray(json.defaultListProducts);
      json.documentTeams = json.documentTeams && BTeam.fromRestArray(json.documentTeams);
      json.emails = json.emails && BEmail.fromRestArray(json.emails);
      return Object.assign(new BUser({}), json);
    }
    return json;
  }

  static fromRestArray(json: any[]): BUser[] {
    return json && json.map((v) => BUser.fromRest(v));
  }

  pk(): number {
    if (this.id) {
      return base64ToPK(this.id);
    }
    return undefined;
  }

  get fName(): string {
    return this.fullName();
  }

  displayInfo(): string {
    const fullName = this.fullName().trim();
    return fullName.length
      ? fullName
      : this.emails.length && this.emails[0].val
      ? this.emails[0].val
      : `ID: ${this.pk()}`;
  }

  displayFullNameAndEmail(): string {
    const fullName = this.fullName().trim();
    const emailExists = this.emails.length && this.emails[0].val;
    return fullName.length && emailExists
      ? `${fullName} (${this.emails[0].val})`
      : fullName.length && !emailExists
      ? fullName
      : emailExists
      ? this.emails[0].val
      : `ID: ${this.pk()}`;
  }

  fullName(): string {
    let comp = [this.firstName, this.lastName];
    return comp.filter((str) => !!str).join(' ');
  }

  isMedicalProcessing(): boolean {
    return this.hasRole(userRoles.medicalProcessing);
  }

  isMim(): boolean {
    return this.hasRole(userRoles.mim);
  }

  isManagingUsers(): boolean {
    return this.hasRole([...userRoles.mim, ...userRoles.userAdmin]);
  }

  isEuropeCardiovascularOrOncologyMim(): boolean {
    return (
      this.hasRoleInTeam(userRoles.mim, 'VGVhbU5vZGU6MTk=') || // MIM role in Daiichi Sankyo Europe Cardiovascular team
      this.hasRoleInTeam(userRoles.mim, 'VGVhbU5vZGU6MzE=') // MIM role in Daiichi Sankyo Europe Oncology team
    );
  }

  isDocumentManager(): boolean {
    return this.hasRole(userRoles.documentManager);
  }

  isDocumentProcessing(): boolean {
    return (
      this.availableTeams().some((team) => team.documentApprovalWithinEnqmed) &&
      this.hasRole(userRoles.documentProcessing)
    );
  }

  isEventManager(): boolean {
    return this.hasRole(userRoles.eventManager);
  }

  isCallCenter(): boolean {
    return this.hasRole(userRoles.callCenter);
  }

  isUserAdmin(): boolean {
    return this.hasRole(userRoles.userAdmin);
  }

  isSysAdmin(): boolean {
    return this.hasRole(userRoles.sysAdmin);
  }

  isAdmin(): boolean {
    return this.hasRole(userRoles.admin);
  }

  isAdminInTeam(teamId: string): boolean {
    return this.hasRoleInTeam(userRoles.admin, teamId);
  }

  isMimInTeam(teamId: string): boolean {
    return this.hasRoleInTeam(userRoles.mim, teamId);
  }

  genderToText(): string {
    return Genders.toText(this.gender);
  }

  titleToText(): string {
    return this.title;
  }

  availableTeams(): BTeam[] {
    let availableTeams = [];
    for (let team of this.affiliations.map((x) => x.team)) {
      if (availableTeams.map((x) => x.id).indexOf(team.id) < 0) {
        availableTeams.push(team);
      }
    }
    return availableTeams;
  }

  // if the user was created AFTER its import_ts (ie the moment when the migration in which the user was imported was performed), it means that a brand new user was
  // created during said migration. Otherwise, it means the user was already in medis and was only updated during the migration.
  isMedisNative(): boolean {
    if (this.firstImportTs && this.createdTs) {
      return !(this.firstImportTs.getTime() <= this.createdTs.getTime());
    } else {
      return true;
    }
  }

  resolveAllowedProducts(): BProduct[] {
    const allProducts = this.canViewProducts.concat(this.defaultListProducts);
    return allProducts
      .filter((val, idx, arr) => arr.findIndex((e) => e.name === val.name) === idx)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  resolveCountryBasedOnAddresses(): BCountry | undefined {
    return (
      this.mainAddress?.country ||
      this.addresses
        ?.filter((a) => a.country?.id)
        .map((a) => a.country)
        .pop()
    );
  }

  resolveCountryBasedOnAffiliations(countries: BCountry[]): BCountry | undefined {
    const countriesByRoles = countries.filter((c) =>
      this.affiliations.some((a) => a.team.name.includes(c.name))
    );
    return countriesByRoles.length === 1 ? countriesByRoles[0] : undefined;
  }

  getTeamIdsWhereUserIsMim(): string[] {
    // Return team IDs where the user has MIM role.
    return this.affiliations
      .filter(
        (affiliation) => affiliation.isApproved() && userRoles.mim.includes(affiliation.role.name)
      )
      .map((affiliation) => affiliation.team.id);
  }

  private hasRole(roleNames: string[], teamPk?: Number): boolean {
    if (!this.affiliations) {
      console.error('user: no roles');
    }
    const roles = this.affiliations.filter(
      (affiliation) =>
        affiliation.isApproved() &&
        (roleNames.includes(affiliation.role.name) || roleNames.includes(affiliation.role.code))
    );
    return roles.length > 0;
  }

  private hasRoleInTeam(roleNames: string[], teamId: string): boolean {
    if (!this.affiliations) {
      console.error('user: no roles');
    }
    const rolesInTeam = this.affiliations.filter(
      (affiliation) =>
        affiliation.isApproved() &&
        affiliation.team.id === teamId &&
        (roleNames.includes(affiliation.role.name) || roleNames.includes(affiliation.role.code))
    );
    return rolesInTeam.length > 0;
  }
}

export namespace BUser {
  export const UNASSIGNED = new BUser({ id: 'Oi0x', firstName: 'Unassigned' });
}

export namespace MTeam {
  export const fragment = gql`
    fragment teamFragment on TeamNode {
      id
      code
      name
      timezoneCode
      notifyInquirer
      notifySales
      createdTs
      editedTs
      salesFullAnswerOnLabel
    }
  `;

  export class BTeamGroupFilter extends BaseModel {
    @rProperty() id: string;
    @rProperty() name: string;
    isActive = true;
    teamIds: string[];

    //relations
    teams: BTeam[];

    constructor(json: any) {
      super(json);
      this.init(json);
    }

    init(json: any) {
      this.teams = parseArray<BTeam>(json, BTeam, 'teams');
    }

    getMId() {
      return base64ToPK(this.id);
    }

    getMName() {
      return this.name;
    }

    static fromRest(json: any): BTeamGroupFilter {
      return Object.assign(new BTeamGroupFilter({}), json);
    }

    static fromRestArray(json: any[]): BTeamGroupFilter[] {
      return json && json.map((object) => BTeamGroupFilter.fromRest(object));
    }
  }
}

export namespace MAuthRequest {
  // fragments will contain only actual values of the object, no references to other tables
  export const fragment = gql`
    fragment authRequestFragment on AuthRequestNode {
      id
      createdTs
      handledTs
      requestRole {
        ...roleFragment
      }
      requestTeam {
        ...teamFragment
      }
      affiliation {
        ...affiliationConnectionFragment
      }
      status {
        ...currentObjectStateFragment
        state {
          ...stateFragment
        }
      }
      handledBy {
        id
        username
        firstName
        lastName
      }
      user {
        id
        username
        firstName
        lastName
      }
      isDelegated
    }
    ${MTeam.fragment}
    ${MAffiliation.fragmentConnection}
    ${MCurrentObjectState.fragment}
    ${MState.fragment}
    ${MRole.fragment}
  `;
}

export namespace MAlignment {
  // fragments will contain only actual values of the object, no references to other tables
  export const fragment = gql`
    fragment alignmentFragment on AlignmentNode {
      id
      name
    }
  `;

  export const fragmentConnection = gql`
    fragment alignmentConnectionFragment on AlignmentNodeConnection {
      edges {
        node {
          ...alignmentFragment
        }
      }
    }
    ${fragment}
  `;
}

export namespace MAnswerTemplate {
  export const fragment = gql`
    fragment answerTemplateFragment on AnswerTemplateNode {
      id
      name
      type
      text
      userId
      offLabel
      hcp
      position
      isDefaultTemplate
      isManuallyModified
      team {
        ...teamFragment
      }
      language {
        ...languageFragment
      }
      countries {
        edges {
          node {
            ...countryFragment
          }
        }
      }
      products {
        edges {
          node {
            ...productFragment
          }
        }
      }
      categories {
        edges {
          node {
            ...categoryFragment
          }
        }
      }
      topics {
        edges {
          node {
            ...topicFragment
          }
        }
      }
    }
    ${MLanguage.fragment}
    ${MCountry.fragment}
    ${MCategory.fragment}
    ${MTopic.fragment}
    ${MProduct.fragment}
    ${MTeam.fragment}
  `;

  export const fragmentConnection = gql`
    fragment answerTemplateConnectionFragment on AnswerTemplateNodeConnection {
      edges {
        node {
          ...answerTemplateFragment
        }
      }
    }
    ${fragment}
  `;
}

export namespace MUserNotification {
  // fragments will contain only actual values of the object, no references to other tables
  export const fragment = gql`
    fragment userNotificationFragment on UserNotificationNode {
      id
      notifType {
        ...notificationTypeFragment
      }
      notifFrequency {
        ...notificationFrequencyFragment
      }
      notifOptions {
        edges {
          node {
            ...notificationOptionFragment
          }
        }
      }
      user {
        id
      }
    }
    ${MNotificationType.fragment}
    ${MNotificationFrequency.fragment}
    ${MNotificationOption.fragment}
  `;
}
