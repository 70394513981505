import { Component, Inject, OnDestroy } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';

import { NameValue, yesNoChoices } from '@mi-tool/consts';
import { tinyMceOptions } from 'app/common/tiny-mce-options';
import { BInquiry, BInteraction } from 'app/modules/data-model/interaction/interaction';
import { MetadataFilter } from 'app/common/util/metadata-filter';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/nomenclature/nomenclatures.service';
import { AuthService } from 'app/auth/auth.service';
import { InteractionsService } from 'app/modules/data-model/interaction/interactions.service';
import { SingleInteractionService } from 'app/modules/data-model/interaction/interaction.service';
import { removeHtml } from 'app/common/util/dom';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';

type ExtractSection = { from: number; to: number; text: string };

@Component({
  selector: 'app-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss'],
})
export class SplitComponent implements OnDestroy {
  readonly choices: NameValue<boolean>[] = yesNoChoices;
  readonly tinyMceOptions = tinyMceOptions();

  inquiry: BInquiry;
  interaction: BInteraction;
  metadata = new MetadataFilter(true);

  extractSections: ExtractSection[] = [];
  originalText: string;
  remainingText: string;
  isRemainingModified = false;
  extractedText: string;
  isExtractModified = false;

  areDetailsShown = false;

  adrRelated: boolean;
  offLabel: boolean;
  productComplaint: boolean;

  splitAnother = false;
  splitInProcess = false;

  private subs = new Subscription();
  private lastExtract: ExtractSection = { from: -1, to: -1, text: '' };

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    nomenclaturesService: NomenclaturesService,
    authService: AuthService,
    private ref: MatDialogRef<SplitComponent>,
    private interactionsService: InteractionsService,
    private singleInteractionService: SingleInteractionService,
    private modalService: GenericDialogService
  ) {
    this.tinyMceOptions.fixed_toolbar_container = '#split-editor-toolbar';
    this.inquiry = data.inquiry;
    this.interaction = data.interaction;
    this.populateDataFields(this.inquiry.question.plainText);
    this.subs.add(
      combineLatest([
        authService.user,
        nomenclaturesService.get(new Scopes().activeCategories().activeTopics()),
      ]).subscribe(([user, resp]) => {
        this.metadata.allTopics = resp.topics;
        this.metadata.allCategories = resp.categories;
        this.metadata.allProducts = user.resolveAllowedProducts();
        this.populateDataFields(this.inquiry.question.plainText);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  textSelection(): void {
    const selection = window.getSelection();
    if (selection.type === 'Range') {
      const range = selection.getRangeAt(0);
      if (range.startContainer === range.endContainer) {
        const start = range.startOffset - 1;
        const end = range.endOffset - 1;
        const text = this.originalText.substr(start, end - start);
        const extract = { from: start, to: end, text: text };
        if (this.lastExtract.from !== extract.from || this.lastExtract.to !== extract.to) {
          this.lastExtract = extract;
          this.extractSections.push(extract);
          this.mergeOverlappingExtractSections();
          this.recalcSplit();
        }
      }
    }
  }

  removeSelection(index: number): void {
    this.extractSections.splice(index, 1);
    this.recalcSplit();
  }

  resetExtractedText(): void {
    this.extractedText = this.resolveExtractText();
    this.isExtractModified = false;
  }

  extractedTextChanged(): void {
    this.isExtractModified = removeHtml(this.extractedText) !== this.resolveExtractText();
  }

  resetRemainingText(): void {
    this.remainingText = this.resolveReaminingText();
    this.isRemainingModified = false;
  }

  remainingTextChanged(): void {
    this.isRemainingModified = removeHtml(this.remainingText) !== this.resolveReaminingText();
  }

  split(inNewInteraction: boolean): void {
    if (inNewInteraction) {
      this.modalService.openSplitInteractionConfirm().subscribe((checkboxValue) => {
        this.performSplit(inNewInteraction, checkboxValue);
      });
    } else {
      this.performSplit(false, false);
    }
  }

  private performSplit(inNewInteraction: boolean, copyAnswerToNewInteraction: boolean): void {
    this.splitInProcess = true;

    this.interactionsService
      .split({
        inquiryId: this.inquiry.pk(),
        extractText: this.extractedText,
        remainingText: this.remainingText,
        inNewInteraction: inNewInteraction,
        copyAnswerToNewInteraction: copyAnswerToNewInteraction,
        productId: this.metadata.selectedProduct && this.metadata.selectedProduct.pk(),
        categoryId: this.metadata.selectedCategory && this.metadata.selectedCategory.pk(),
        topicId: this.metadata.selectedTopic && this.metadata.selectedTopic.pk(),
        adrRelated: this.adrRelated,
        offLabel: this.offLabel,
        productQualityComplain: this.productComplaint,
      })
      .subscribe(
        () => {
          this.singleInteractionService.refreshInteraction(this.interaction.id);
          if (this.splitAnother) {
            this.populateDataFields(removeHtml(this.remainingText));
            this.extractSections = [];
            this.isExtractModified = this.isRemainingModified = false;
            this.splitInProcess = false;
          } else {
            this.ref.close();
          }
        },
        () => {
          this.splitInProcess = false;
        }
      );
  }

  private mergeOverlappingExtractSections(): void {
    const textIdxs = [];
    for (const es of this.extractSections) {
      for (let i = es.from; i < es.to; ++i) {
        textIdxs[i] = true;
      }
    }
    this.extractSections = [];
    for (let f = 0; f < textIdxs.length; ++f) {
      if (textIdxs[f]) {
        for (let t = f; t <= textIdxs.length; ++t) {
          if (!textIdxs[t] || t === textIdxs.length) {
            this.extractSections.push({ from: f, to: t, text: this.originalText.substr(f, t - f) });
            f = t;
            break;
          }
        }
      }
    }
  }

  private recalcSplit(): void {
    if (!this.isExtractModified) {
      this.extractedText = this.resolveExtractText();
    }
    if (!this.isRemainingModified) {
      this.remainingText = this.resolveReaminingText();
    }
  }

  private resolveExtractText(): string {
    let extractedText = '';
    for (const extract of this.extractSections) {
      extractedText += this.originalText.substr(extract.from, extract.to - extract.from);
    }
    return extractedText.replace(/\s+/g, ' ').trim();
  }

  private resolveReaminingText(): string {
    if (!this.extractSections.length) {
      return this.originalText;
    }
    let remainingText = '';
    if (this.extractSections[0].from > 0) {
      remainingText += this.originalText.substr(0, this.extractSections[0].from);
    }
    for (let i = 0; i < this.extractSections.length - 1; ++i) {
      const gapFrom = this.extractSections[i].to;
      const gapTo = this.extractSections[i + 1].from;
      remainingText += this.originalText.substr(gapFrom, gapTo - gapFrom);
    }
    if (this.extractSections[this.extractSections.length - 1].to < this.originalText.length) {
      remainingText += this.originalText.substr(
        this.extractSections[this.extractSections.length - 1].to
      );
    }
    return !remainingText ? this.originalText : remainingText.replace(/\s+/g, ' ').trim();
  }

  private populateDataFields(originalText: string): void {
    this.originalText = this.remainingText = originalText.replace(/\s+/g, ' ').trim();
    this.extractedText = '';

    this.metadata.refresh(this.inquiry.product, this.inquiry.category, this.inquiry.topic);
    this.adrRelated = this.inquiry.adrRelated;
    this.offLabel = this.inquiry.offLabel;
    this.productComplaint = this.inquiry.productComplaint;
  }
}
