import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { RolePermissionChange, RolesService } from 'app/modules/data-model/role/role.service';
import { BRole } from 'app/modules/data-model/data-model.module';
import { BPermission } from 'app/modules/data-model/role/role';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AuditTrailService } from 'app/modules/data-model/audit-trail/audit-trail.service';
import { Message } from '../message/message.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  templateUrl: './permission-picker.component.html',
  styleUrl: './permission-picker.component.scss',
})
export class PermissionPickerComponent implements OnDestroy {
  permission: BPermission;
  roles: BRole[] = [];
  selectedRoleIds: number[] = [];
  changes: RolePermissionChange[] = [];
  message: Message = Message.error('THERE_ARE_NO_CHANGES', false);

  private subs: Subscription = new Subscription();

  constructor(
    private ref: MatDialogRef<PermissionPickerComponent>,
    private roleService: RolesService,
    private auditTrail: AuditTrailService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initPermissionName(name: string): void {
    this.subs.add(
      this.roleService.getSingle(name).subscribe({
        next: (settings) => {
          this.permission = settings.permission;
          this.roles = settings.roles;
          this.selectedRoleIds = [...settings.enabledForRoleIds];
        },
        error: (resp) => (this.message = Message.httpError('Load Permissions', resp)),
      })
    );
  }

  openAuditTrailDialog(): void {
    this.auditTrail.openNonUserAuditTrailDialog('roles', undefined);
  }

  isChanged(roleId: number): boolean {
    return this.changes.findIndex((c) => c.roleId === roleId) !== -1;
  }

  toggleRoleSelection(roleId: number, event: MatCheckboxChange): void {
    const idx = this.selectedRoleIds.indexOf(roleId);
    if (idx === -1) {
      this.selectedRoleIds.push(roleId);
    } else {
      this.selectedRoleIds.splice(idx, 1);
    }
    const chIdx = this.changes.findIndex((c) => c.roleId === roleId);
    if (chIdx === -1) {
      this.changes.push(new RolePermissionChange(this.permission.id, roleId, event.checked));
    } else {
      this.changes.splice(chIdx, 1);
    }
    this.message = this.changes.length ? undefined : Message.error('THERE_ARE_NO_CHANGES', false);
  }

  save(nextAction: '' | 'close' = ''): void {
    this.subs.add(
      this.roleService.save(this.changes).subscribe({
        next: () => {
          this.changes = [];
          this.message = Message.info('SAVED');
          if (nextAction === 'close') {
            this.ref.close();
          }
        },
        error: (resp) => (this.message = Message.httpError('Save Permissions', resp)),
      })
    );
  }
}
