<h2 mat-dialog-title>
  {{
    (createVersionMode
      ? 'CREATE_NEW_VERSION'
      : localizationMode
      ? 'COMPLETE_LOCALIZATION_PROCESS'
      : 'CREATE_NEW_DOCUMENT'
    ) | translate
  }}
  <i class="far fa-times-circle medis-icon-large" (click)="handleDialogClosing()"></i>
</h2>
<mat-dialog-content>
  <div *ngIf="documentData && document">
    <div
      class="medis-flex-container-between"
      [ngClass]="documentData.typeId ? 'text-14-800-dark' : 'text-14-800-coral'"
    >
      {{ 'SELECT_YOUR_DEC_TYPE' | translate }}
      <mat-divider></mat-divider>
    </div>
    <div class="medis-flex-container-even medis-pv-5">
      <app-document-card-type
        *ngFor="let typeCode of availableTypeCodes"
        (click)="setType(typeCode)"
        [documentType]="typeCode"
        [isSelected]="document.type?.code === typeCode"
      ></app-document-card-type>
    </div>
    <div class="document-create-details">
      <div>
        <div class="text-14-800-dark medis-flex-container-between">
          {{ 'DOC_DETAILS' | translate }}
          <mat-divider></mat-divider>
        </div>
        <div class="medis-flex-container-between medis-pt-10 text-14-500-dark">
          <span
            >{{ 'START_DATE' | translate }}:
            {{ documentData.scheduledPublishingDate | dateChecker }}</span
          >
          <span>{{ 'VERSION' | translate }}: {{ documentData.versionNumber }}</span>
        </div>
        <mat-form-field class="mat-full-width form-field-wrapper">
          <mat-label>{{ 'DOC_ID' | translate }}</mat-label>
          <input
            matInput
            [(ngModel)]="documentData.documentId"
            maxlength="100"
            autocomplete="off"
          />
        </mat-form-field>
        <div *ngIf="document.type" class="medis-flex-container-between">
          <mat-form-field
            class="medis-flex-grow form-field-wrapper"
            [ngClass]="documentData.shortTitle || !document.isBinaryFile() ? '' : 'invalid'"
          >
            <mat-label
              >{{ 'Title' | translate }}
              {{ document.isBinaryFile() ? '(' + ('REQUIRED' | translate) + ')' : '' }}</mat-label
            >
            <input matInput [(ngModel)]="documentData.shortTitle" autocomplete="off" />
          </mat-form-field>
          <i
            *ngIf="!document.isBinaryFile()"
            class="far fa-question-circle medis-icon"
            [matTooltip]="'DOCUMENT_TITLE_FOR_WORD' | translate"
          ></i>
        </div>
        <app-search-select
          [config]="teamConfig"
          [required]="true"
          [(selectedValue)]="documentData.ownerId"
          (selectedValueChange)="setTeam($event)"
        ></app-search-select>
        <div class="text-14-800-dark medis-flex-container-between">
          {{ 'APPROVED_FOR' | translate }}
          <mat-divider></mat-divider>
        </div>
        <app-search-select
          [config]="countryConfig"
          [multiple]="true"
          [required]="true"
          [disabled]="
            countryConfig.rawItems.length === 1 &&
            documentData.countriesId &&
            !!documentData.countriesId.length
          "
          [(selectedValue)]="documentData.countriesId"
          (selectedValueChange)="changeCountry($event)"
        ></app-search-select>
        <app-search-select
          [config]="therapeuticAreaConfig"
          [required]="true"
          [disabled]="
            therapeuticAreaConfig.rawItems.length === 1 && !!documentData.therapeuticAreaId
          "
          [(selectedValue)]="documentData.therapeuticAreaId"
        ></app-search-select>
        <app-search-select
          [config]="languageConfig"
          [(selectedValue)]="documentData.languageId"
          (selectedValueChange)="changeLanguage($event)"
        ></app-search-select>
        <app-search-select
          [config]="productConfig"
          [multiple]="true"
          [required]="true"
          [(selectedValue)]="documentData.productsId"
        ></app-search-select>
        <app-search-select
          [config]="categoryConfig"
          [multiple]="true"
          [required]="true"
          [(selectedValue)]="documentData.categoriesId"
          (selectedValueChange)="setCategories($event)"
        ></app-search-select>
        <app-search-select
          [config]="topicConfig"
          [multiple]="true"
          [required]="true"
          [(selectedValue)]="documentData.topicsId"
        ></app-search-select>
      </div>
      <div>
        <div class="text-14-800-dark medis-flex-container-between">
          {{ 'PROCESS_DETAILS' | translate }}
          <mat-divider></mat-divider>
        </div>
        <app-date-time-picker
          [style]="''"
          [label]="'SCHEDULED_PUBLISHING_DATE'"
          [mode]="'single'"
          [(value)]="documentData.scheduledPublishingDate"
        ></app-date-time-picker>
        <app-date-time-picker
          [style]="''"
          [label]="'SCHEDULED_EXP_DATE'"
          [mode]="'single'"
          [(value)]="documentData.scheduledExpirationDate"
        ></app-date-time-picker>
        <mat-form-field class="mat-full-width form-field-wrapper">
          <mat-label>{{ 'KEYWORDS' | translate }}</mat-label>
          <input matInput [(ngModel)]="documentData.keywords" autocomplete="off" />
        </mat-form-field>
        <div class="text-14-800-dark medis-flex-container-between medis-pb-10">
          {{ 'TARGET_AUDIENCE' | translate }}
          <mat-divider></mat-divider>
        </div>
        <div *ngFor="let audience of audiences">
          <mat-checkbox [(ngModel)]="audience['selected']" (ngModelChange)="triggerAudience()">{{
            audience.name
          }}</mat-checkbox>
        </div>

        <div class="text-14-800-dark medis-flex-container-between medis-pb-10">
          {{ 'UPLOAD' | translate }}
          <mat-divider></mat-divider>
        </div>
        <app-document-upload
          [(fileContent)]="documentData.file"
          (externalUrlChange)="documentData.url = $event"
          [isValidUrlInput]="isValidUrlInput"
        ></app-document-upload>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    class="medis-button-main"
    [disabled]="!canSave()"
    (click)="handleDocumentCreate()"
  >
    {{ (autoApproveDocument ? 'DONE' : 'NEXT') | translate | titlecase }}
  </button>
</mat-dialog-actions>
