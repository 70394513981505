import { PermissionHandler } from 'app/auth/auth.service';
import * as perm from 'app/common/common/constants/permissions';

function navMenu(): NavItem[] {
  const enqPerms = perm.PERMS_ACCESS_ENQUIRIES;
  const repoPerms = perm.PERM_VIEW_REPOSITORY;
  return [
    item('INTERACTIONS', 'fa fa-clipboard-list', ['inq/my-teams-open-inq'], ...enqPerms),
    repo('REPOSITORY', 'far fa-folder-open', ['management', 'approvals'], repoPerms),
    item('REPORTS', 'fa fa-chart-bar', ['reports'], perm.PERM_VIEW_REPORTS),
    {
      name: 'ADMINISTRATION',
      icon: 'fas fa-lock',
      children: [
        admin('Therapeutic_Areas', ['therapeutic-areas'], perm.PERM_VIEW_THERAPEUTIC_AREAS),
        admin('USER_ADMIN', ['users'], perm.PERM_VIEW_USER_ADMIN),
        admin('ROLES', ['roles'], perm.PERM_VIEW_ROLES),
        admin('RULES', ['rules'], perm.PERM_VIEW_RULES),
        admin('HCP_ADMINISTRATION', ['hcps', 'specializations'], perm.PERM_VIEW_HCP_ADMIN),
        admin('EVENTS', ['events'], perm.PERM_VIEW_EVENTS),
        admin('MAILBOXES', ['mailbox'], perm.PERM_VIEW_MAILBOXES),
        admin('TASKS', ['tasks'], perm.PERM_VIEW_TASKS),
        admin('MIGRATION_ERRORS', ['migration-errors'], perm.PERM_VIEW_MIGRATION_ERRORS),
        admin('METADATA', ['metadata'], perm.PERM_VIEW_METADATA),
        admin('TEAMS', ['teams'], perm.PERM_VIEW_TEAMS),
        admin('AUTHORIZATION_REQUESTS', ['auth-requests'], ...perm.PERMS_ACCESS_AUTH_REQUESTS),
      ],
    },
  ];
}

function filterRights(handler: PermissionHandler, navItems: NavItem[]): NavItem[] {
  return navItems.filter((i) => {
    i.children = i.children && filterRights(handler, i.children);
    return (i.href || i.children?.length) && (!i.permissions || handler.has(...i.permissions));
  });
}

export type NavItem = {
  name: string;
  icon?: string;
  href?: string;
  allRefs?: string[];
  permissions?: string[];
  children?: NavItem[];
};

function repo(name: string, icon: string, allRefs: string[], ...permissions: string[]) {
  allRefs = allRefs.map((ref) => `repository/${ref}`);
  return item(name, icon, allRefs, ...permissions);
}

function admin(name: string, allRefs: string[], ...permissions: string[]): NavItem {
  allRefs = allRefs.map((ref) => `administration/${ref}`);
  return item(name, undefined, allRefs, ...permissions);
}

function item(name: string, icon: string, allRefs: string[], ...permissions: string[]): NavItem {
  return { name, icon, href: allRefs[0], permissions, allRefs };
}

export function resolveNavItems(handler: PermissionHandler): NavItem[] {
  return filterRights(handler, navMenu());
}
