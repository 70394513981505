import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { apiUrl } from 'app/common/url-resolver.service';
import { Observable } from 'rxjs';
import { BRule } from './rule';
import { PaginationParams, SimplePage } from 'app/common/types';
import { isNil, omitBy } from 'lodash';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RulesService {
  private readonly URL = apiUrl('rules');

  constructor(private http: HttpClient) {}

  create(rule: BRule): Observable<void> {
    return this.http.post<void>(this.URL, rule);
  }

  edit(rule: Partial<BRule>): Observable<void> {
    const { typeName, ...ruleData } = rule;
    return this.http.put<void>(this.URL, ruleData);
  }

  get(params: RuleParams): Observable<SimplePage<BRule>> {
    return this.http
      .get<SimplePage<BRule>>(this.URL, { params: omitBy(params, isNil) })
      .pipe(map((res) => ({ ...res, records: BRule.fromRestArray(res.records) })));
  }
}

export type RuleParams = PaginationParams & {
  orderBy?: string;
  id?: number;
  name?: string;
  typeIds?: string;
  validFrom?: string;
  validTo?: string;
  status?: string;
};
