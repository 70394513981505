import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { RuleDialogComponent } from './rule-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Helpers } from '@mi-tool/utils/helpers';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import { debounceTime, filter } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { Sort } from '@angular/material/sort';
import { ESort } from '@mi-tool/enums';
import { PageEvent } from '@angular/material/paginator';
import { DateRange } from 'app/common/types';
import { DateUtility } from 'app/common/date-utility';
import { BRule, newRule } from 'app/modules/data-model/rule/rule';
import { RuleParams, RulesService } from 'app/modules/data-model/rule/rule.service';
import { newNameIdConfig } from 'app/common/common/search-select/search-select.config';
import {
  NomenclaturesService,
  Scopes,
} from 'app/modules/data-model/nomenclature/nomenclatures.service';
import { AuditTrailService } from 'app/modules/data-model/audit-trail/audit-trail.service';

@Component({
  selector: 'app-rule',
  templateUrl: './rule.component.html',
  styleUrls: ['../administration-list.scss'],
})
export class RuleComponent implements OnInit, OnDestroy {
  @Input() preselectTypeName: 'Enquiry answer approval';

  dataSource = new MatTableDataSource<BRule>([]);
  displayedColumns = ['id', 'name', 'type', 'validFrom', 'validTo', 'isActive', 'audit'];

  searchedId: number = null;
  searchedName: string = null;
  searchedTypeIds: number[] = [];
  searchedStatus = 'all';

  pageSizeOptions = [5, 10, 25, 50, 100];
  pageSize = this.pageSizeOptions[1];
  pageIndex = 0;
  totalRecords = 0;

  typeConfig = newNameIdConfig([]);

  private formattedValidFrom: string = null;
  private formattedValidTo: string = null;
  private orderBy: string = null;
  private appliedFilters$ = new BehaviorSubject<RuleParams>(null);
  private lastAppliedFilters: RuleParams = null;
  private subs = new Subscription();

  constructor(
    private auditTrailService: AuditTrailService,
    private dialogService: GenericDialogService,
    private helpers: Helpers,
    private rulesService: RulesService,
    private msgService: MessageHandlerService,
    private nomenclatureService: NomenclaturesService
  ) {}

  ngOnInit(): void {
    this.nomenclatureService.get(new Scopes().ruleTypes()).subscribe((response) => {
      this.typeConfig = newNameIdConfig(response.ruleTypes);
      if (this.preselectTypeName) {
        const searchedTypeId = response.ruleTypes.find(
          (t) => t.name === this.preselectTypeName
        )?.id;
        if (searchedTypeId) {
          this.searchedTypeIds = [searchedTypeId];
          this.triggerSearch();
        }
      }
    });

    this.subs.add(
      this.appliedFilters$
        .pipe(
          debounceTime(300),
          filter((appliedFilters) => !isEqual(appliedFilters, this.lastAppliedFilters))
        )
        .subscribe((appliedFilters) => {
          this.fetchRules();
          this.lastAppliedFilters = appliedFilters;
        })
    );
    this.triggerSearch();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  triggerSearch(): void {
    this.appliedFilters$.next(this.getFilters());
  }

  onSortChange(event: Sort): void {
    this.orderBy = event.direction
      ? (event.direction === 'desc' ? ESort.DESC : ESort.ASC) + event.active
      : null;
    this.triggerSearch();
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.triggerSearch();
  }

  onDateRangeChange(event: DateRange, isValidFrom = true): void {
    this[isValidFrom ? 'formattedValidFrom' : 'formattedValidTo'] =
      DateUtility.formatDateRange(event);
    this.triggerSearch();
  }

  openRuleDialog(rule?: BRule): void {
    const dialogRef = this.dialogService.open(RuleDialogComponent, { autoFocus: 'dialog' });
    dialogRef.componentInstance.rule = newRule(rule);
    if (this.preselectTypeName) {
      dialogRef.componentInstance.preselectTypeId = this.searchedTypeIds[0];
    }
    this.subs.add(
      dialogRef.afterClosed().subscribe((reload: boolean) => reload && this.fetchRules())
    );
  }

  switchActivity(rule: BRule): void {
    this.rulesService.edit({ id: rule.id, isActive: !rule.isActive }).subscribe({
      next: () => {
        this.msgService.info('The rule has been successfully edited!');
        this.fetchRules();
      },
      error: (error) =>
        this.msgService.httpError('An error occurred while editing the rule', error),
    });
  }

  openAuditTrailDialog(event: MouseEvent, rule: BRule = null): void {
    this.auditTrailService.openNonUserAuditTrailDialog('rules', rule, event);
  }

  private getFilters(): RuleParams {
    return {
      pageIndex: this.pageIndex + 1,
      pageSize: this.pageSize,
      orderBy: this.orderBy,
      id: this.searchedId,
      name: this.helpers.trimmedStringOrNull(this.searchedName),
      typeIds: this.helpers.arrayToStringOrNull(this.searchedTypeIds),
      validFrom: this.formattedValidFrom,
      validTo: this.formattedValidTo,
      status: this.searchedStatus != 'all' ? this.searchedStatus : null,
    };
  }

  private fetchRules(): void {
    this.rulesService.get(this.appliedFilters$.getValue()).subscribe({
      next: (response) => {
        this.totalRecords = response.totalRecords;
        this.dataSource.data = response.records;
        this.helpers.scrollToTop();
      },
      error: (error) =>
        this.msgService.httpError('An error occurred while fetching the rules', error),
    });
  }
}
