import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { PermissionEditService } from './permission-edit.service';
import { GenericDialogService } from '../generic-dialog/generic-dialog.service';
import { PermissionPickerComponent } from './permission-picker.component';
import { AuthService, PermissionHandler } from 'app/auth/auth.service';

@Component({
  selector: 'app-permission-edit',
  templateUrl: './permission-edit.component.html',
  styleUrl: './permission-edit.component.scss',
})
export class PermissionEditComponent implements OnDestroy {
  @Input() style: string = '';
  @HostBinding('class.d-none') hideHost: boolean = true;
  notViewable: boolean = true;

  @Input() set permissionName(name: string) {
    this._permissionName = name;
    this.resolveAccess((handler) => handler.has(name));
  }

  get permissionName(): string {
    return this._permissionName;
  }

  @Input() set permissionNames(names: string[]) {
    this._permissionName = names[0];
    this.resolveAccess((handler) => handler.hasAll(...names));
  }

  @Input() set skip(val: boolean) {
    this._skip = val;
    if (val) {
      this.hideHost = this.notViewable = false;
    }
  }

  get skip(): boolean {
    return this._skip;
  }

  @Input() set hasFallback(val: boolean) {
    this._hasFallback = val;
    if (val) {
      this.hideHost = false;
    }
  }

  private _permissionName: string;
  private _skip: boolean = false;
  private _hasFallback: boolean = false;
  private subs = new Subscription();

  constructor(
    public permissionEdit: PermissionEditService,
    private dialog: GenericDialogService,
    private authService: AuthService
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  edit(): void {
    const ref = this.dialog.open(PermissionPickerComponent);
    ref.componentInstance.initPermissionName(this.permissionName);
  }

  private resolveAccess(hasAccess: (handler: PermissionHandler) => boolean): void {
    this.subs.add(
      this.authService.permissions.subscribe((handler) => {
        this.notViewable = !this.skip && !hasAccess(handler);
        this.hideHost = !this._hasFallback && this.notViewable;
      })
    );
  }
}
