import { gql } from 'apollo-angular';

import * as MAttachment from '../attachment/attachment';
import { MMedicalDocument } from '../medical-document/medical-document';
import * as MMedicalDocumentVersionFile from '../medical-document-version-file/medical-document-version-file';
import * as MMedicalDocumentType from '../medical-document-type/medical-document-type';
import { MHistory } from '../interaction/interaction';

export const mutateHistoryQuery = gql`
  mutation updateHistory($params: UpdateHistoryInput!) {
    updateHistory(input: $params) {
      history {
        ...historyFragment
        interaction {
          id
          editedTs
        }
        plainText
        attachments {
          ...attachmentConnectionFragment
        }
        medicalDocuments {
          edges {
            node {
              ...medicalDocumentFragment
              type {
                ...medicalDocumentTypeFragment
              }
              currentVersion {
                id
                files {
                  ...medicalDocumentVersionFileConnectionFragment
                }
              }
            }
          }
        }
      }
      newHistory {
        ...newHistoryFragment
        plainText
        attachments {
          ...attachmentConnectionFragment
        }
        medicalDocuments {
          edges {
            node {
              ...medicalDocumentFragment
              type {
                ...medicalDocumentTypeFragment
              }
              currentVersion {
                id
                files {
                  ...medicalDocumentVersionFileConnectionFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${MHistory.fragment}
  ${MAttachment.fragmentConnection}
  ${MHistory.fragmentNew}
  ${MMedicalDocument.fragment}
  ${MMedicalDocumentVersionFile.fragmentConnection}
  ${MMedicalDocumentType.fragment}
`;
