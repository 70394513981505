import { Injectable } from '@angular/core';
import { UrlResolverService } from 'app/common/url-resolver.service';
import { BInteraction, BInteractionAnswer } from '../interaction/interaction';
import { BMDWordSection } from '../md-word-section/md-word-section';
import { BMedicalDocumentVersionFile } from '../medical-document-version-file/medical-document-version-file';
import { BMedicalDocumentVersion } from '../medical-document/medical-document';

@Injectable({
  providedIn: 'root',
})
export class RepositoryBrowserService {
  constructor(private urlResolver: UrlResolverService) {}

  displayInteractionAnswerAsPdf(interaction: BInteraction): void {
    window.open(
      this.urlResolver.apiUrlForPath([
        'api-rest',
        'repository',
        'browser',
        'export-answer-pdf',
        '?interactionId=' + interaction.id + '&time=' + new Date().toString(),
      ])
    );
  }

  displayAnswerAsPdf(answer: BInteractionAnswer): void {
    window.open(
      this.urlResolver.apiUrlForPath([
        'api-rest',
        'repository',
        'browser',
        'export-answer-pdf',
        '?interactionAnswerId=' + answer.id + '&time=' + new Date().toString(),
      ])
    );
  }

  downloadHistoryMedicalDocumentVersion(
    version: BMedicalDocumentVersion,
    historyId: string,
    outputFormat: string = 'pdf'
  ): void {
    let restUrl = this.urlResolver.apiUrlForPath([
      'api-rest',
      'repository',
      'browser',
      'medicaldoc',
      version.pk().toString(),
      'download/',
      `?format=${outputFormat ? outputFormat : 'pdf'}`,
    ]);
    if (historyId) {
      restUrl += '&historyObjectId=' + historyId;
    }
    window.open(restUrl);
  }

  downloadSectionMedicalDocumentVersion(
    version: BMedicalDocumentVersion,
    section: BMDWordSection
  ): void {
    window.open(
      this.urlResolver.apiUrlForPath([
        'api-rest',
        'repository',
        'browser',
        'medicaldoc',
        version.pk().toString(),
        'download/',
        '?format=pdf&sections=' + section.order,
      ])
    );
  }

  linkToMedicalDocumentFile(file: BMedicalDocumentVersionFile): string {
    return this.urlResolver.apiUrlForPath([
      'api-rest',
      'repository',
      'browser',
      file.pk().toString(),
      'download/',
      '?format=pdf',
    ]);
  }

  downloadMedicalDocumentFile(file: BMedicalDocumentVersionFile): void {
    window.open(this.linkToMedicalDocumentFile(file));
  }
}
