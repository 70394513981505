<div class="wrapper" [class.enabled]="permissionEdit.enabled && !skip" [ngClass]="style">
  @if (!notViewable || skip) {
  <ng-content></ng-content>
  } @else {
  <ng-content select="not-viewable"></ng-content>
  }
  <div
    class="overlay"
    (click)="$event.stopPropagation(); edit()"
    [matTooltip]="'PERMISSION_EDIT_TOOLTIP' | translate: { name: permissionName }"
  ></div>
</div>
