<!-- SEARCH -->
<ng-container *ngIf="interaction && !inquiryView && !isMergeSuggestion">
  <mat-form-field class="mat-full-width form-field-wrapper medis-pb-20">
    <mat-label>{{ 'Search_inquiries' | translate }}</mat-label>
    <input matInput [(ngModel)]="textSearch.val" type="search" autocomplete="off" />
  </mat-form-field>
  <mat-table #table [dataSource]="dataSource" class="medis-table simple-header">
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef>{{ 'TITLE' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div>
          <div class="text-16-900-dark truncated-text">{{ element.title }}</div>
          <div [innerHtml]="element.description | sanitizeHtml"></div>
        </div>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="team">
      <mat-header-cell *matHeaderCellDef>{{ 'TEAM' | translate }}</mat-header-cell>
      <mat-cell *matCellDef="let element">
        <span class="text-16-900-dark">{{ element.team }}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      (click)="selectRow(row)"
      [ngClass]="{ active: row?.active }"
      *matRowDef="let row; columns: displayedColumns"
    ></mat-row>
  </mat-table>
  <button
    *ngIf="!hideLoadMoreButton"
    mat-button
    class="medis-button-basic load-more-button"
    (click)="loadMoreSuggestions()"
  >
    {{ 'LOAD_MORE' | translate }}
  </button>
</ng-container>
<!-- VISUALIZE SELECTED INQUIRY -->
<ng-container *ngIf="inquiryView && interaction && mergeData.targetInteraction">
  <button
    *ngIf="!isMergeSuggestion"
    mat-icon-button
    class="medis-icon-button"
    (click)="cancelInquiryViewMode()"
  >
    <i class="fas fa-chevron-left text-16-900-azure"></i>
  </button>
  <div class="medis-pv-20">
    <div class="medis-flex-container-start">
      <span class="text-14-800-dark medis-pr-10">
        {{ (isMergeSuggestion ? 'THIS_INQ_IS' : 'MERGE_REASON') | translate }}:
      </span>
      <app-multi-toggle [choices]="mergeReasonChoices" [(value)]="mergeReason"></app-multi-toggle>
    </div>
    <div *ngIf="mergeReason" class="medis-flex-container-between text-13-500-light pt-1">
      <div>
        <span *ngFor="let mergeReason of mergeReasonChoices | filterBy: 'value':mergeReason">
          {{ mergeReason.description | translate }}
        </span>
        <mat-error *ngIf="mergeData.validationMsg" class="text-start">
          {{ mergeData.validationMsg | translate }}
        </mat-error>
        <mat-error *ngIf="hasClarificationQuestionWithoutAnswer" class="text-start">
          {{ 'DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER' | translate }}
        </mat-error>
      </div>
      <div>
        <mat-checkbox
          *ngIf="showReopenCheckbox"
          [(ngModel)]="reopenAfterMerge"
          [disabled]="isReopenDisabled"
          class="reopen-checkbox"
        >
          {{ 'RE_OPEN_THE_TARGET_' | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>
  <div
    *ngIf="!(mergeReason === mergeReasons.newInquiry && mergeData.targetClosedOrInReview)"
    class="medis-flex-container-between"
  >
    <div class="compare-side">
      <div class="text-16-800-dark">
        {{ 'MERGED_INTERACTION' | translate }}: {{ interaction | pk }} {{ interaction?.title }}
      </div>
      <!-- For merge as new inquiry we can select more than one inquiry. -->
      <app-similar-inquiry-link
        *ngIf="mergeReason === mergeReasons.newInquiry"
        [isMultipleSelect]="true"
        [interaction]="interaction"
        (selectedInquiryIdsChange)="handleSelectedInquiryIdsChange($event)"
      ></app-similar-inquiry-link>
      <!-- For merge as clarification question we can select only one inquiry. -->
      <app-similar-inquiry-link
        *ngIf="mergeReason === mergeReasons.clarificationQuestion"
        [interaction]="interaction"
        (selectInquiryChange)="handleSelectedInquiryIdsChange([$event.id])"
      ></app-similar-inquiry-link>
      <!-- For all except merge as new inquiry and clarification question, we don't have inquiry selection. -->
      <app-similar-inquiry-link
        *ngIf="![mergeReasons.newInquiry, mergeReasons.clarificationQuestion].includes(mergeReason)"
        [showSelectContainer]="false"
        [interaction]="interaction"
      ></app-similar-inquiry-link>
    </div>
    <i class="fas fa-chevron-right text-24-800-dark"></i>
    <div class="compare-side">
      <div class="text-16-800-dark">
        {{ 'TARGET_INTERACTION' | translate }}: {{ mergeData.targetInteraction | pk }}
        {{ mergeData.targetInteraction?.title }}
      </div>
      <!-- For merge as clarification question we can select more than one inquiry. -->
      <app-similar-inquiry-link
        *ngIf="mergeReason === mergeReasons.clarificationQuestion"
        [isMultipleSelect]="true"
        [interaction]="mergeData.targetInteraction"
        (selectedInquiryIdsChange)="handleSelectedInquiryIdsChange($event, false)"
      ></app-similar-inquiry-link>
      <!-- For all except merge as clarification question, we don't have inquiry selection. -->
      <app-similar-inquiry-link
        *ngIf="mergeReason !== mergeReasons.clarificationQuestion"
        [showSelectContainer]="false"
        [interaction]="mergeData.targetInteraction"
      ></app-similar-inquiry-link>
    </div>
  </div>
</ng-container>
