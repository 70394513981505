import { gql } from 'apollo-angular';

import { BaseModel, rProperty } from '../data-model';

export class BPhone extends BaseModel {
  @rProperty() id: string;
  @rProperty() label: string;
  @rProperty() val: string;
  @rProperty() isMain: boolean;
  @rProperty() isDeleted: boolean;
  @rProperty(Date) createdTs: Date;
  @rProperty(Date) editedTs: Date;
  @rProperty() importedId: string;
  @rProperty() importedSource: string;
  @rProperty(Date) importedTs: string;

  constructor(json: any) {
    super(json);
    this.init(json);
  }

  init(json: any) {
    if (!json) {
      this.val = '';
    }
    for (let field of ['isMain', 'isDeleted']) {
      // Need to keep the true value since we can also clone the email which is already true
      this[field] = json[field] == 'TRUE' || json[field] === true ? true : false;
    }
  }

  static fromRest(json: any): BPhone {
    return json && Object.assign(new BPhone({}), json);
  }

  static fromRestArray(json: any[]): BPhone[] {
    return json && json.map((v) => BPhone.fromRest(v));
  }

  isValid(): boolean {
    const val = this.val && this.val.trim();
    if (this.val && val.length === 0) {
      return false;
    }
    if (!this.isMain && !val) {
      return true;
    }
    return this.regularPhoneValidation(val);
  }

  regularPhoneValidation(phone: string): boolean {
    return /^\+?\d[\d\s]{7,}$/.test(phone);
  }
}

// fragments will contain only actual values of the object, no references to other tables
export const fragment = gql`
  fragment phoneFragment on PhoneNode {
    id
    label
    val
    isMain
    createdTs
    editedTs
    isDeleted
    importedId
    importedSource
    importedTs
  }
`;

export const fragmentNew = gql`
  fragment newPhoneFragment on NewPhoneNode {
    id
    label
    val
    isMain
    isDeleted
  }
`;

export const fragmentConnection = gql`
  fragment phoneConnectionFragment on PhoneNodeConnection {
    edges {
      node {
        ...phoneFragment
      }
    }
  }
  ${fragment}
`;

export const fragmentNewConnection = gql`
  fragment newPhoneConnectionFragment on NewPhoneNodeConnection {
    edges {
      node {
        ...newPhoneFragment
      }
    }
  }
  ${fragmentNew}
`;
