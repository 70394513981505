import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Channels } from '@mi-tool/consts/const';
import { localUrl } from 'app/common/url-resolver.service';
import { MessageHandlerService } from 'app/common/common/message-handler/message-handler.service';
import {
  AttachmentsService,
  BAttachment,
  BHistory,
  BInquirer,
  BInquiry,
  BInteraction,
  BMedicalDocument,
  SingleInteractionService,
} from 'app/modules/data-model/data-model.module';
import { InteractionsService } from 'app/modules/data-model/interaction/interactions.service';
import { SelectInquiryComponent } from './select-inquiry/select-inquiry.component';
import { HISTORY_DIALOG_MODES } from 'app/common/types';
import { HistoryFilters } from '../detail-history-filters';
import { NgbdModalUser } from 'app/common/common/modal/modal-user/modal-user.component';
import { MERGE_REASONS } from '@mi-tool/consts';
import {
  InteractionMergeService,
  MergeReason,
} from 'app/modules/data-model/interaction/interaction-merge.service';
import { Helpers } from '@mi-tool/utils/helpers';
import { AuthService } from 'app/auth/auth.service';
import { PERM_PROCESS_ENQUIRIES } from 'app/common/common/constants/permissions';
import { GenericDialogService } from 'app/common/common/generic-dialog/generic-dialog.service';
import { EmailMessageComponent } from 'app/modules/administration/mailbox/email-polling/email-message/email-message.component';
import { EHistory } from './enchanted-history';

type MergeAsCqAndNewInqData = {
  id: number;
  text: string;
  channel: string;
  mergedFromId?: number;
};

type DetailChange = {
  rawField: string;
  field: string;
  from: string | BInquirer | MergeAsCqAndNewInqData[] | string[] | number[];
  to: string | BInquirer | MergeAsCqAndNewInqData[] | string[] | number[];
};

@Component({
  selector: 'app-detail-history-interaction',
  templateUrl: './detail-history-interaction.component.html',
  styleUrls: ['./detail-history-interaction.component.scss'],
})
export class DetailHistoryInteractionComponent implements OnDestroy {
  visibleHistory: EHistory[] = [];
  detailChanges: { [historyId: string]: DetailChange[] } = {};
  @Input() mode?: HISTORY_DIALOG_MODES = 'history';
  @Input() loggedInUserIsSysAdmin: boolean;
  disabled: boolean = true;
  jsonDetailChangeHistoryIds: string[] = [];
  readonly categoriesWithTranslatableText = ['fu_attempt'];
  readonly splitTextRawFields = ['text_before_split', 'text_after_split', 'text_to_split'];
  readonly referencesRawFields = [
    'reference_question_id',
    'reference_source',
    'reference_id',
    'reference_type',
  ];
  readonly eventHistoryCategories = [
    'add_event_to_int',
    'update_event_within_int',
    'remove_event_from_int',
  ];
  readonly categoriesWithCustomJson = [
    'merge_as_inquiry',
    'closed_as_merge',
    'merge_as_new_inquiry',
  ];
  readonly jsonSemiDetailChangeCategories = [
    'forward_to_dept',
    'forward_to_user',
    'update_watchers',
  ];
  readonly jsonDetailChangeCategories = [
    'update_detail',
    'copy_answer',
    'delete_pii',
    'revert_status',
    'change_status',
    'change_status_to_answered',
    'change_status_to_approved_and_send_answer',
    'update_answer',
    'reject_answer_with_status',
    'reject_answer_with_status_revert',
    'add_answer_with_status',
    'added_disclaimer',
    'removed_disclaimer',
    'updated_disclaimer',
    'update_team_after_inquirer_country_change',
    'update_team_and_assignment_after_inquirer_country_change',
    'personal_assignment_on_role_archive',
    'notification',
    'interaction_split',
    'team_assignment',
    'add_event_to_int',
    'update_event_within_int',
    'remove_event_from_int',
    'forward_to_dept',
    'forward_to_user',
    'update_watchers',
    'interaction_references',
  ];
  readonly freeTextRawFields = [
    'question',
    'answer',
    'answer_draft',
    'greetings',
    'signature',
    'disclaimer',
    'gdpr_notification',
  ];
  readonly historySkipRawFields = [
    'inquirer',
    'inquirer_details',
    'team_update_details',
    'assignment_removal_on_team_update_details',
    'assignment_removal_on_role_archive_details',
    'merge_as_inquiry',
    'merge_as_new_inquiry',
    'question_id',
    'question_ids',
    'disclaimer_info',
    'reason_for_replacement',
    'reason_for_removal',
    'reason_for_removal_from_interaction',
    'reason_for_inclusion',
    'reason_for_inclusion_from_interaction',
    'reason_for_disc_update',
    'reason_for_update',
    'reason_for_update_accessory',
    'due_date',
    'notification',
    ...this.splitTextRawFields,
    ...this.referencesRawFields,
  ];
  readonly mergeHistoryCategories = [
    'fu_response',
    'other',
    'internal',
    'error',
    'merge_as_new_inquiry',
    'merge_as_inquiry',
    'merge_as_duplicate',
    'interaction_merged',
    'closed_as_merge',
  ];
  readonly historyCategoriesForMergedFromLabel = [
    'answer',
    'fu_request',
    'fu_response',
    'other',
    'internal',
    'error',
    'merge_as_new_inquiry',
    'merge_as_inquiry',
    'interaction_merged',
  ];
  readonly discRawFields = [
    'disclaimer_info',
    'reason_for_replacement',
    'reason_for_removal',
    'reason_for_removal_from_interaction',
    'reason_for_inclusion',
    'reason_for_inclusion_from_interaction',
    'reason_for_disc_update',
  ];
  readonly historySkipHistoryInfoRow = ['reject_answer_note', 'simultaneous_assignment'];
  readonly skipCategoriesForTextContainer = ['personal_assignment', 'add_attachment'];

  private allHistory: BHistory[] = [];
  private historyFilters: HistoryFilters.HistoryFilter[];
  private _interaction: BInteraction;
  private _inquiry: BInquiry;
  fieldNameMap = {
    delete_pi_on_close: 'DELETE_PERSONAL_DATA',
    adr_related: 'ADR_RELATED',
    adr_related_reference_id: 'ADR_REF_ID',
    off_label: 'OFF_LABEL',
    product_complaint: 'PRODUCT_QUALITY_COMPLAINT',
    product_complaint_reference_id: 'PQC_REF_ID',
    topic: 'TOPIC',
    category: 'CATEGORY',
    product: 'PRODUCT',
    product_resolved_via_ai: 'PRODUCT_RESOLVED_VIA_AI',
    priority: 'PRIORITY',
    outgoing_channel: 'OUT_CHANNEL',
    need_srd: 'SRD_NEEDED',
    answer_unavailable: 'ANSWER_UNAVAILABLE',
    incoming_channel: 'INC_CHANNEL',
    question: 'QUESTION_TEXT',
    question_id: 'QUESTION_ID',
    question_ids: 'QUESTION_IDS',
    answer_draft: 'ANSWER_TEXT',
    answer: 'ANSWER_TEXT',
    inquirer: 'INQUIRER',
    subject: 'EMAIL_SUBJECT',
    interaction_title: 'INTERACTION_TITLE',
    greetings: 'Greetings',
    signature: 'Signature',
    disclaimer: 'Disclaimer',
    disclaimer_info: 'DISCLAIMER_INFO',
    reason_for_replacement: 'REASON_FOR_REPLACEMENT',
    reason_for_removal: 'REASON_FOR_REMOVAL',
    reason_for_removal_from_interaction: 'REASON_FOR_REMOVAL',
    reason_for_inclusion: 'REASON_FOR_INCLUSION',
    reason_for_inclusion_from_interaction: 'REASON_FOR_INCLUSION',
    template_id: 'ID',
    reason_for_update: 'REASON_FOR_UPDATE',
    reason_for_disc_update: 'REASON_FOR_UPDATE',
    gdpr_notification: 'NOTIFICATION_SENT_TO_THE_INQ',
    delete_pii: 'DELETE_INQUIRER_PII',
    team: 'TEAM',
    assignment: 'ASSIGNMENT',
    due_date: 'DUE_DATE',
    text_before_split: 'QUESTION_TEXT_BEFORE_SPLIT',
    text_after_split: 'QUESTION_TEXT_AFTER_SPLIT',
    text_to_split: 'QUESTION_TEXT_AFTER_SPLIT',
    reference_question_id: 'QUESTION_ID',
    reference_source: 'REFERENCE_SOURCE',
    reference_id: 'REFERENCE_ID',
    reference_type: 'REFERENCE_TYPE',
  };
  private categoryToLabel = {
    personal_assignment: 'changed assignment',
    team_assignment: 'changed assignment',
    update_detail: 'updated enquiry detail',
    change_details: 'started review',
    delete_inquiry: 'deleted question',
    other: 'added note',
    reject_answer_note: 'added note',
    error: 'error',
    bounce_detected: 'delivery failure',
    closed_as_merge: 'merged this enquiry into',
    interaction_split: 'split enquiry',
    delete_attachment: 'deleted attachment',
    add_attachment: 'added attachment',
    close_review: 'closed review',
    copy_answer: 'copied answer',
    link_repo_doc: 'linked document from repository',
    unlink_repo_doc: 'unlinked document from repository',
    add_answer: 'submitted for approval',
    add_answer_with_status: 'submitted for approval',
    reject_answer: 'rejected answer',
    reject_answer_with_status: 'rejected answer',
    reject_answer_with_status_revert: 'reverted status',
    approve_answer: 'approved answer',
    send_answer: 'sent answer',
    reopen_answer: 'reopened enquiry for new answer',
    approved_and_send_answer: 'approved and sent answer',
    change_status_to_approved_and_send_answer: 'approved and sent answer',
    updated_answer_due_to_product_update: 'updated answer due to product update',
    updated_answer_due_to_incoming_channel_update: 'updated answer due to incoming channel update',
    updated_answer_due_to_deleted_question: 'updated answer due to deleted question',
    unspam: 'moved the message from spam list to inbox',
    create: 'created',
    change_status: 'changed status',
    change_status_to_answered: 'changed status (saved without email)',
    saved_answer_without_email: 'saved answer without email',
    update_answer: 'updated answer',
    forward_to_dept: 'forwarded interaction data',
    forward_to_user: 'forwarded interaction data',
    fu_request: 'Started follow up',
    fu_response: 'Follow up response',
    access: 'Access',
    answer: 'Answer',
    closed_as_duplicate: 'closed this interaction as a duplicate of',
    duplicate_of_this: 'closed the following interaction as a duplicate of current interaction:',
    merge_as_duplicate: 'merged interaction',
    resend_fu_request: 'SENT_FOLLOW_UP_REMINDER',
    lost_fu: 'CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION',
    delete_pii: 'deleted inquirer personal information',
    added_disclaimer: 'added a disclaimer',
    removed_disclaimer: 'removed a disclaimer',
    updated_disclaimer: 'updated a disclaimer',
    update_team_after_inquirer_country_change:
      'updated inquirer details{INTERACTION_ID/HCP}, which led to changed team in the current interaction',
    update_team_and_assignment_after_inquirer_country_change:
      'updated inquirer details{INTERACTION_ID/HCP}, which led to changed team and assignment in the current interaction',
    personal_assignment_on_role_archive:
      'archived a user role, which led to{ADDITIONAL_INFO}changed assignment in the current interaction',
    create_question_from_fu: 'CREATED_QUESTION_FROM_FU_RESPONSE',
    fu_attempt: 'ATTEMPTED_FOLLOW_UP',
    notification: 'RECEIVES_THE_FOLLOWING_NOTIFICATION',
    add_event_to_int: 'SET_INTERACTION_AS_BEING_PART_OF_AN_EVENT',
    update_event_within_int: 'UPDATED_EVENT',
    remove_event_from_int: 'REMOVED_INTERACTION_AS_BEING_PART_OF_AN_EVENT',
    update_watchers: 'UPDATE_WATCHERS',
  };
  private subs = new Subscription();

  constructor(
    private attachmentsService: AttachmentsService,
    private interactionsService: InteractionsService,
    private singleInteractionService: SingleInteractionService,
    private messageService: MessageHandlerService,
    private interactionMergeService: InteractionMergeService,
    private helpers: Helpers,
    private dialogService: GenericDialogService,
    authService: AuthService
  ) {
    this.subs.add(
      authService.permissions.subscribe((handler) => {
        this.disabled = !handler.has(PERM_PROCESS_ENQUIRIES);
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  @Input()
  set interaction(interaction: BInteraction) {
    if (interaction && (!this._interaction || interaction.pk() !== this._interaction.pk())) {
      this._interaction = interaction;
    }
  }

  get interaction(): BInteraction {
    return this._interaction;
  }

  @Input()
  set inquiry(inquiry: BInquiry) {
    this._inquiry = inquiry;
  }

  @Input()
  set interactionFilter(historyFilterValues: string[]) {
    this.historyFilters = HistoryFilters.resolveFilters(historyFilterValues);
    this.refreshFilter();
  }

  @Input()
  set history(val: InteractionsService.History) {
    this.detailChanges = {};
    const history = val.history;
    history
      .filter(
        (h) =>
          this.jsonDetailChangeCategories.includes(h.category) || this.isCustomJsonDetailChange(h)
      )
      .forEach((h) => {
        this.jsonDetailChangeHistoryIds.push(h.id);
        this.adjustSplitHistory(h);
        this.detailChanges[h.id] = this.decodeData(h, val.inquirers);
      });
    for (let i = 0; i < history.length; ++i) {
      if (history[i].category === 'approve_answer') {
        if (
          history[i + 1]?.category === 'change_status' &&
          this.detailChanges[history[i + 1].id][0].to.toString().includes('Answered') &&
          history[i + 2]?.category === 'send_answer'
        ) {
          history[i + 1].category = 'change_status_to_approved_and_send_answer';
          history.splice(i + 2, 1);
          history.splice(i, 1);
        } else if (history[i - 1]?.category === 'send_answer') {
          history[i].category = 'approved_and_send_answer';
          history.splice(i - 1, 1);
        } else if (history[i + 1]?.category === 'send_answer') {
          history[i].category = 'approved_and_send_answer';
          history.splice(i + 1, 1);
        }
      }

      if (
        history[i].category === 'reject_answer' &&
        ['change_status', 'revert_status'].includes(history[i - 1]?.category)
      ) {
        if (history[i + 1]?.category === 'other') {
          history[i + 1].category = 'reject_answer_note';
        }
        history[i - 1].category =
          history[i - 1]?.category === 'change_status'
            ? 'reject_answer_with_status'
            : 'reject_answer_with_status_revert';
        history.splice(i, 1);
      }

      if (
        history[i].category === 'change_status' &&
        this.detailChanges[history[i].id][0].to.toString().includes('Answered') &&
        history[i + 1]?.category === 'send_answer'
      ) {
        history[i].category = 'change_status_to_answered';
        history.splice(i + 1, 1);
      }

      if (history[i].category === 'send_answer' && history[i].text) {
        history[i].category = 'saved_answer_without_email';
        history[i].text = null;
      }

      if (history[i].category === 'add_answer' && history[i - 1]?.category === 'change_status') {
        history[i - 1].category = 'add_answer_with_status';
        history.splice(i, 1);
      }

      if (
        (history[i].category === 'personal_assignment' &&
          history[i - 1]?.category === 'team_assignment') ||
        (history[i].category === 'team_assignment' &&
          history[i - 1]?.category === 'personal_assignment')
      ) {
        if (history[i].category === 'personal_assignment') {
          [history[i], history[i - 1]] = [history[i - 1], history[i]];
        }
        history[i].category = 'simultaneous_assignment';
      }

      if (
        history[i].category === 'team_assignment' &&
        this.detailChanges[history[i].id].length > 1
      ) {
        if (this.detailChanges[history[i].id][0].rawField.toLowerCase() === 'team_update_details') {
          history[i].category = 'update_team_after_inquirer_country_change';
        } else if (
          this.detailChanges[history[i].id][0].rawField.toLowerCase() ===
          'assignment_removal_on_team_update_details'
        ) {
          history[i].category = 'update_team_and_assignment_after_inquirer_country_change';
        } else if (
          this.detailChanges[history[i].id][1].rawField.toLowerCase() ===
          'assignment_removal_on_role_archive_details'
        ) {
          history[i].category = 'personal_assignment_on_role_archive';
        }
      }

      if (history[i].category === 'update_answer' && history[i].createdBy?.username === 'system') {
        if (
          !this.detailChanges[history[i].id][0].field.includes('subject_update_due_to_') &&
          this.detailChanges[history[i].id][0].rawField.toLowerCase() === 'subject' &&
          history[i + 1].category === 'update_detail'
        ) {
          if (this.detailChanges[history[i + 1].id][0].rawField.toLowerCase() === 'product') {
            history[i].category = 'updated_answer_due_to_product_update';
          }
          if (
            this.detailChanges[history[i + 1].id][0].rawField.toLowerCase() ===
              'incoming_channel' ||
            history[i + 1].text.toLowerCase().includes('incoming_channel')
          ) {
            history[i].category = 'updated_answer_due_to_incoming_channel_update';
          }
        }
        if (this.detailChanges[history[i].id][0].field.includes('subject_update_due_to_')) {
          const category_sub_string = 'updated_answer_due_to_';
          if (this.detailChanges[history[i].id][0].field.includes('product')) {
            history[i].category = category_sub_string + 'product_update';
          } else if (this.detailChanges[history[i].id][0].field.includes('incoming_channel')) {
            history[i].category = category_sub_string + 'incoming_channel_update';
          } else if (this.detailChanges[history[i].id][0].field.includes('deleted_question')) {
            history[i].category = category_sub_string + 'deleted_question';
          }
          this.detailChanges[history[i].id][0].field = this.fieldNameMap['subject'];
        }
      }
      this.parseHistoryDescription(history[i], val.inquirers);
    }
    this.allHistory = history.concat([BHistory.createEntry(this.interaction)]);
    this.refreshFilter();
  }

  // Cast can be replaced with satisfies after angular upgrade.
  getFromData(data: DetailChange): MergeAsCqAndNewInqData[] {
    return data.from as MergeAsCqAndNewInqData[];
  }

  historyLabel(history: BHistory): string {
    if (
      history.category === 'delete_pii' &&
      this.detailChanges[history.id] &&
      this.detailChanges[history.id].length === 1
    ) {
      const detailChange = this.detailChanges[history.id][0];
      return detailChange.from === 'YES'
        ? 'UNMARKED_INQUIRER_PII_DELETE'
        : 'MARKED_INQUIRER_PII_DELETE';
    } else if (history.category === 'resend_fu_request' && !history.text) {
      return 'FOLLOW_UP_RESEND';
    } else if (history.category === 'update_team_after_inquirer_country_change') {
      return this.insertValueInHistoryCategoryLabel(history, 'team_update_details');
    } else if (history.category === 'update_team_and_assignment_after_inquirer_country_change') {
      return this.insertValueInHistoryCategoryLabel(
        history,
        'assignment_removal_on_team_update_details'
      );
    } else if (history.category === 'personal_assignment_on_role_archive') {
      return this.insertValueInHistoryCategoryLabel(
        history,
        'assignment_removal_on_role_archive_details'
      );
    } else if (
      ['added_disclaimer', 'removed_disclaimer', 'updated_disclaimer'].includes(history.category)
    ) {
      return this.resolveDiscHistoryLabel(history);
    } else if (history.category === 'interaction_references') {
      return this.resolveReferencesHistoryLabel(history);
    }
    return this.categoryToLabel[history.category] || history.category;
  }

  resolveInteractionUrl(interactionId: number): string {
    return localUrl('detail', interactionId);
  }

  getMessageLabel(history: BHistory): string {
    if (this.historyCategoriesForMergedFromLabel.includes(history.category)) {
      return 'MERGED_FROM';
    } else if (history.category === 'interaction_split') {
      return history.mergeOrDuplicateParam === this._interaction.pk()
        ? 'split within this enquiry'
        : history.interactionId === this._interaction.pk()
        ? 'split from different enquiry'
        : 'split to separate enquiry';
    } else {
      return this.historyLabel(history);
    }
  }

  resolveMergeReasonTranslationKeys(history: BHistory) {
    const mergeReason = this.resolveMergeReason(history);
    return this.interactionMergeService.getMergeReasonTranslationKeys(mergeReason);
  }

  previewUser(userId: number): void {
    this.dialogService.open(NgbdModalUser, { data: { userId: userId } });
  }

  previewInquirer(inquirerId: number): void {
    this.dialogService.open(NgbdModalUser, { data: { inquirerId: inquirerId } });
  }

  copyTextToAnswer(internalCom: BHistory): void {
    this.selectInquiry((inquiry) => {
      const text = this.stripBodyTags(inquiry.answer.text) + this.stripBodyTags(internalCom.text);
      this.subs.add(
        this.interactionsService
          .updateText(this._interaction.isNew(), inquiry.answer.pk(), text)
          .subscribe({
            next: () => {
              this.singleInteractionService.refreshInteraction(this._interaction.id);
            },
            error: (error) => {
              this.messageService.httpError('Copy Text to Answer', error);
            },
          })
      );
    });
  }

  copyToAnswer(attachment: BAttachment | BMedicalDocument): void {
    this.selectInquiry((inquiry) => {
      this.subs.add(
        this.attachmentsService.copyToAnswer(attachment.pk(), inquiry.answer.pk()).subscribe({
          next: () => {
            this.singleInteractionService.refreshInteraction(this._interaction.id);
          },
          error: (error) => {
            this.messageService.httpError('Copy Attachment to Answer', error);
          },
        })
      );
    });
  }

  emailMessagePreview(historyId: string, messageId: number): void {
    this.interactionsService.getHistoryMessage(historyId, messageId).subscribe({
      next: (message) => {
        const ref = this.dialogService.open(EmailMessageComponent, { autoFocus: 'dialog' });
        ref.componentInstance.message = message;
        ref.componentInstance.canOpenFile = true;
        ref.componentInstance.canViewFullMessageData = false;
      },
      error: (error) =>
        this.messageService.httpError('An error occurred while fetching the message', error),
    });
  }

  isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }

  private resolveReferencesHistoryLabel(history: BHistory): string {
    let history_label = '';
    const interaction_reference_id = this.detailChanges[history.id][0];

    if (interaction_reference_id.from !== 'BLANK' && interaction_reference_id.to !== 'BLANK') {
      history_label = 'updated interaction reference ID';
    } else if (interaction_reference_id.from !== 'BLANK') {
      history_label = 'deleted interaction reference ID';
    } else {
      history_label = 'added interaction reference ID';
    }

    this.detailChanges[history.id] = this.detailChanges[history.id].filter(
      (item) => item.rawField !== 'ID'
    );
    return history_label;
  }

  private resolveDiscHistoryLabel(history: BHistory): string {
    const action = this.detailChanges[history.id].find(
      (h) =>
        ['REASON_FOR_INCLUSION', 'REASON_FOR_REMOVAL', 'REASON_FOR_UPDATE'].includes(h.field) &&
        !['reason_for_removal_from_interaction', 'reason_for_inclusion_from_interaction'].includes(
          h.rawField
        )
    )?.from;
    if (!action) {
      return this.categoryToLabel[history.category] || history.category;
    }
    return `${action} and subsequently ${this.categoryToLabel[history.category]} ${
      history.category !== 'removed_disclaimer' ? 'into' : 'from'
    } the current interaction`;
  }

  private insertValueInHistoryCategoryLabel(history: BHistory, historyKey: string): string {
    const keyValue = JSON.parse(history.text)[historyKey][1];
    if (history.category === 'personal_assignment_on_role_archive') {
      const replacement = keyValue ? ' user deactivation and ' : ' ';
      return this.categoryToLabel[history.category].replace('{ADDITIONAL_INFO}', replacement);
    } else {
      let replacement = ' in HCP administration';
      if (keyValue && keyValue !== this.interaction.pk()) {
        replacement = ' in Interaction ID: ' + keyValue;
      } else if (keyValue && keyValue === this.interaction.pk()) {
        replacement = '';
      }
      return this.categoryToLabel[history.category].replace('{INTERACTION_ID/HCP}', replacement);
    }
  }

  private selectInquiry(callback: (inquiry: BInquiry) => void): void {
    if (this._interaction.inquiries.length > 1) {
      const modalRef = this.dialogService.open(SelectInquiryComponent);
      modalRef.componentInstance.interaction = this._interaction;
      modalRef.afterClosed().subscribe((inquiry) => {
        if (inquiry) {
          callback(inquiry);
        }
      });
    } else {
      callback(this._interaction.inquiries[0]);
    }
  }

  private refreshFilter(): void {
    const filters = this.historyFilters;
    this.visibleHistory = this.allHistory
      .filter((h) => !this._inquiry || !h.inquiryId || this._inquiry.id === h.inquiryId)
      .filter((h) => HistoryFilters.includes(filters, h))
      .map((h) => EHistory.from(h));

    // Do not show merge as thank you message in internal communication dialog.
    if (this.mode === 'internal_comm') {
      this.visibleHistory = this.visibleHistory.filter(
        (history) => !(history.category === 'other' && history.historyDescription)
      );
    }
  }

  private stripBodyTags(html: string): string {
    if (!html) {
      html = '';
    } else if (html.includes('<body>')) {
      html = html.substring(html.indexOf('<body>') + 6, html.indexOf('</body>'));
    }
    return html;
  }

  private decodeData(history: BHistory, inquirers: BInquirer[]): DetailChange[] {
    try {
      const parsed = JSON.parse(history.text);
      return Object.keys(parsed).map((field) => {
        return {
          rawField: field,
          field: this.fieldNameMap[field] || field,
          from: this.mapValue(parsed[field][0], field, inquirers, true),
          to: this.mapValue(parsed[field][1], field, inquirers),
        };
      });
    } catch (err) {
      console.error('Corrupted data for history: ' + history.id + ' ' + err);
      return [];
    }
  }

  private mapValue(
    value: any,
    field: string,
    inquirers: BInquirer[],
    isFromValue: boolean = false
  ): string | BInquirer | MergeAsCqAndNewInqData[] | number[] | string[] {
    if (field === 'text_to_split') {
      return value;
    }
    const isUnset = (val) =>
      val === undefined || val === null || (typeof val === 'string' && val.trim().length === 0);
    const mapPriority = (val) =>
      val === 'normal' ? 'NORMAL' : val === 'high' ? 'HIGH' : val === 'critical' ? 'URGENT' : val;
    if (isUnset(value)) {
      return 'BLANK';
    } else if (value === true) {
      return 'YES';
    } else if (value === false) {
      return 'NO';
    } else if (field === 'inquirer' || field === 'inquirer_details') {
      return inquirers.find((i) => i.pk() == value) || value.toString();
    } else if (field === 'priority') {
      return mapPriority(value);
    } else if (field === 'incoming_channel' || field === 'outgoing_channel') {
      return Channels.resolveTranslationKey(value);
    } else if (['merge_as_new_inquiry', 'merge_as_inquiry'].includes(field)) {
      if (isFromValue) {
        value.forEach((sourceQ) => {
          sourceQ.channel = Channels.resolveTranslationKey(sourceQ.channel);
        });
      } else if (field === 'merge_as_inquiry') {
        value = this.helpers.arrayOfItemsToText(
          value.map((obj) => obj.id.toString()),
          false
        );
      }
      return value;
    } else if (
      ['Added watcher', 'Added watchers', 'Removed watcher', 'Removed watchers'].includes(field)
    ) {
      if (isFromValue) {
        return value.split(',').map((item) => item.trim());
      }
      return JSON.parse(value);
    } else if (field == 'notification' && !isFromValue) {
      try {
        const parsedValue = JSON.parse(value);
        return typeof parsedValue === 'object' && parsedValue !== null ? parsedValue : value;
      } catch {
        return value;
      }
    } else {
      return value.toString();
    }
  }

  private resolveMergeReason(history: BHistory): MergeReason {
    let mergeReason;
    if (history.category === 'closed_as_merge' && history.historyDescription) {
      mergeReason = history.historyDescription['mergeReason'];
    } else if (history.category === 'fu_response' && history.historyDescription) {
      mergeReason = MERGE_REASONS.followUpResponse;
    } else if (history.category === 'other' && history.historyDescription) {
      mergeReason = MERGE_REASONS.thankYouMessage;
    } else {
      const mergeReasonMap = {
        internal: MERGE_REASONS.internalMessage,
        error: MERGE_REASONS.errorMessage,
        merge_as_new_inquiry: MERGE_REASONS.newInquiry,
        merge_as_inquiry: MERGE_REASONS.clarificationQuestion,
        merge_as_duplicate: MERGE_REASONS.duplicate,
      };
      mergeReason = mergeReasonMap[history.category];
    }
    return mergeReason;
  }

  private parseHistoryDescription(history: BHistory, inquirers: BInquirer[]): void {
    if (
      ((this.mergeHistoryCategories.includes(history.category) && history.mergeOrDuplicateParam) ||
        ['fu_request', 'fu_response'].includes(history.category)) &&
      history.historyDescription &&
      typeof history.historyDescription === 'string'
    ) {
      history.historyDescription = JSON.parse(history.historyDescription);

      const inquirerId = history.historyDescription?.['inquirerId'];
      if (inquirerId && history.category === 'fu_response') {
        history.historyDescription['inquirer'] = this.mapValue(inquirerId, 'inquirer', inquirers);
      }
    }
  }

  private isCustomJsonDetailChange(history: BHistory): boolean {
    if (!this.categoriesWithCustomJson.includes(history.category)) {
      return false;
    }
    return this.isHistoryTextJson(history);
  }

  private adjustSplitHistory(history: BHistory): void {
    if (history.category === 'interaction_split' && history.text) {
      const isJson = this.isHistoryTextJson(history);
      if (!isJson) {
        history.text = JSON.stringify({ text_before_split: [history.text] });
      }
    }
  }

  private isHistoryTextJson(history: BHistory): boolean {
    try {
      const parsedText = JSON.parse(history.text);
      return Boolean(parsedText && typeof parsedText === 'object');
    } catch (error) {
      return false;
    }
  }
}
