import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface CheckboxItem {
  id: string;
  name: string;
  checked: boolean;
  tooltip?: string;
}

export interface CheckboxGroup {
  id: string;
  name: string;
  checked: boolean;
  indeterminate: boolean;
  tooltip?: string;
  items: CheckboxItem[];
}

@Component({ selector: 'app-checkbox-groups', templateUrl: './checkbox-groups.component.html' })
export class CheckboxGroupsComponent implements OnInit {
  @Input() groups: CheckboxGroup[] = [];
  @Output() selectionChanged = new EventEmitter<Record<string, string[]>>();
  masterChecked = false;
  masterIndeterminate = false;

  ngOnInit(): void {
    this.updateMasterState();
  }

  updateMasterSelection(): void {
    this.groups.forEach((group) => {
      group.indeterminate = false;
      group.checked = this.masterChecked;
      group.items.forEach((cb) => (cb.checked = this.masterChecked));
    });
    this.updateMasterState();
  }

  updateGroupSelection(group: CheckboxGroup): void {
    group.indeterminate = false;
    group.items.forEach((cb) => (cb.checked = group.checked));
    this.updateMasterState();
  }

  updateCheckbox(group: CheckboxGroup): void {
    const allChecked = group.items.every((cb) => cb.checked);
    group.checked = allChecked;
    group.indeterminate = !allChecked && group.items.some((cb) => cb.checked);
    this.updateMasterState();
  }

  private updateMasterState(): void {
    this.masterChecked = this.groups.every((g) => g.checked);
    this.masterIndeterminate = this.groups.some(
      (g) => g.indeterminate || (g.items.some((cb) => cb.checked) && !this.masterChecked)
    );
    this.emitSelectedData();
  }

  private emitSelectedData(): void {
    const selectedData: Record<string, string[]> = {};
    this.groups.forEach((group) => {
      const selectedOptions = group.items.filter((cb) => cb.checked).map((cb) => cb.id);
      if (selectedOptions.length > 0) {
        selectedData[group.id] = selectedOptions;
      }
    });
    this.selectionChanged.emit(selectedData);
  }
}
